import React from 'react';
import CpuIcon from 'react-feather/dist/icons/cpu';
import GlobeIcon from 'react-feather/dist/icons/globe';
import LockIcon from 'react-feather/dist/icons/lock';
import PackageIcon from 'react-feather/dist/icons/package';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import SettingsPanel, { Page } from '../SettingsPanel';

import Deployment from './Deployment';
import Domains from './Domains';
import Environment from './Environment';
import Variables from './Variables';

interface Props {
	application: ApplicationResponse,
}

export default function ApplicationSettings( props: Props & RouteComponentProps ) {
	const { application } = props;
	const root = props.match.url;

	const subpages: ( Page | false )[] = [
		{
			title: 'Deployment',
			icon: PackageIcon,
			path: `${ root }/deployment`,
			component: () => (
				<Deployment
					application={ application }
				/>
			),
		},
		{
			title: 'Domains',
			icon: GlobeIcon,
			path: `${ root }/domains`,
			component: () => (
				<Domains
					application={ application }
				/>
			),
		},
		{
			title: 'Environment',
			icon: CpuIcon,
			path: `${ root }/environment`,
			component: () => (
				<Environment
					application={ application }
				/>
			),
		},
		application.flags.secrets && {
			title: 'Variables & Secrets',
			icon: LockIcon,
			path: `${ root }/variables`,
			route: `${ root }/variables/:type?`,
			component: props => (
				<Variables
					application={ application }
					type={ props.match.params.type || 'app' }
				/>
			),
		},
	];

	return (
		<SettingsPanel
			defaultPath={ `${ root }/deployment` }
			pages={ subpages.filter( Boolean ) as Page[] }
		/>
	);
}
