import React from 'react';

import { PostResponse } from '../../api';
import { ConnectedProps, withApiData } from '../../lib/with-api-data';
import ErrorBlock from '../ErrorBlock';
import Loader from '../Loader';
import PageTitle from '../PageTitle';
import Pagination from '../Pagination';
import withPagination, { PaginationProps } from '../withPagination';

import Content from './Content';
import Header from './Header';

import './index.css';

type DataTypes = {
	archiveData: PostResponse[],
}
export function Archive( props: PaginationProps & ConnectedProps<typeof connect> ) {
	const {
		archiveData,
		page,
		setPage,
	} = props;
	if ( archiveData.isLoading ) {
		return (
			<div className="BlogArchive">
				<Loader />
			</div>
		);
	}
	if ( archiveData.error )  {
		return (
			<ErrorBlock message={ archiveData.error.message } />
		);
	}

	const totalPages = parseInt( archiveData.response?.headers.get( 'X-WP-TotalPages' ) || '1', 10 );

	return (
		<section className="BlogArchive">
			<PageTitle
				title="Blog"
			/>

			<Header />

			{ archiveData.isLoading ? (
				<>
					<div className="BlogPost-Content animated-background"></div>
					<div className="BlogPost-Content animated-background"></div>
				</>
			) : (
				<>
					{ archiveData.data.map( postObject => {
						return (
							<Content
								key={ postObject.slug }
								postObject={ postObject }
							/>
						);
					} ) }
					{ totalPages > 1 && (
						<Pagination
							currentPage={ page }
							totalPages={ totalPages }
							onNewer={ () => setPage( page - 1 ) }
							onOlder={ () => setPage( page + 1 ) }
						/>
					) }
				</>
			) }
		</section>
	);
}

const connect = withApiData<DataTypes, PaginationProps>( props => ( {
	archiveData: `wp/v2/posts?per_page=10&page=${ props.page }&_embed=author`,
} ) );

export default withPagination( connect( Archive ) );
