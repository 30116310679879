import qs from 'qs';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import PageTitle from '../PageTitle';

import XRayOverviewStatistics from './Statistics';
import TimeSelector from './TimeSelector';
import TraceList from './TraceList';
import { TimeFilter } from './util';

// Hack to ensure API-wrapped components can be refreshed. Should be replaced
// with React.forwardRef in the future.
type RefreshableRef = React.Component & {
	getWrappedInstance(): null | ( React.Component & {
		onRefresh(): void;
	} );
};

type FilterQueryString = {
	filter?: {
		filter?: string;
		before?: string;
		after?: string;
		duration?: string;
	}
};

interface Props {
	application: string,
	onSelectTrace( traceId: string ): void,
}

export class XRayOverview extends React.Component<RouteComponentProps & Props> {
	statsRef: RefreshableRef|null = null;
	tracesRef: RefreshableRef|null = null;

	onSelectFilter = ( filter: any ) => {
		const currentFilter = this.getFilter();

		// Quote numeric values in the status field.
		const filterValue = filter.field === 'http.status' ? filter.value : `"${filter.value}"`;

		// Append filter to the existing one, if set.
		const newFilter = `${filter.field} = ${filterValue}`;
		const nextFilter = currentFilter ? `${ currentFilter } AND ${ newFilter }` : newFilter;
		this.onUpdateFilter( nextFilter );
	};

	onUpdateFilter = ( filter: string ) => {
		this.onUpdateRawFilter( {
			filter,
			...this.getSelectedTime(),
		} );
	};

	onUpdateTime = ( time: TimeFilter ) => {
		this.onUpdateRawFilter( {
			filter: this.getFilter(),
			...time,
		} );
	};

	onUpdateRawFilter( summariesFilter: any ) {
		const next = `?${qs.stringify( { filter: summariesFilter } )}`;
		if ( next === this.props.history.location.search ) {
			// Filter is the same, so trigger a refresh instead.
			this.onRefresh();
			return;
		}

		this.props.history.push( next );
	}

	getSelectedTime(): TimeFilter {
		if ( ! this.props.location.search ) {
			return {
				duration: 15 * 60,
			};
		}

		const parsed = qs.parse( this.props.location.search.substr( 1 ) ) as FilterQueryString;
		const { before, after, duration } = parsed?.filter || {};
		if ( duration ) {
			return {
				duration: parseInt( duration, 10 ),
			};
		}

		return {
			before,
			after,
		};
	}

	getFilter() : string {
		if ( ! this.props.location.search ) {
			return '';
		}

		const params = qs.parse( this.props.location.search.substr( 1 ) ) as FilterQueryString;
		return params.filter?.filter || '';
	}

	onRefresh = () => {
		this.statsRef?.getWrappedInstance()?.onRefresh();
		this.tracesRef?.getWrappedInstance()?.onRefresh();
	};

	render() {
		const { application, onSelectTrace } = this.props;
		const selectedTime = this.getSelectedTime();
		const filter = this.getFilter();

		return (
			<>
				<PageTitle title="XRay" />

				<TimeSelector
					selected={ selectedTime }
					onRefresh={ this.onRefresh }
					onSelect={ this.onUpdateTime }
				/>
				<XRayOverviewStatistics
					// @ts-ignore
					ref={ ref => this.tracesRef = ref }
					application={ application }
					time={ selectedTime }
				/>

				<TraceList
					// @ts-ignore
					ref={ ref => this.statsRef = ref }
					application={ application }
					filter={ filter }
					time={ selectedTime }
					onSelectFilter={ this.onSelectFilter }
					onSelectTrace={ onSelectTrace }
					onUpdateFilter={ this.onUpdateFilter }
				/>
			</>
		);
	}
}

export default withRouter<RouteComponentProps & Props, any>( XRayOverview );
