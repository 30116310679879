import React from 'react';

import { ApplicationResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import ApplicationLogsWarning from '../ApplicationLogsWarning';
import NoItemsFoundBlock from '../NoItemsFoundBlock';
import SortableTable, { Columns } from '../SortableTable';

import Header from './Header';
import Level from './Level';
import Timestamp from './Timestamp';
import { Filter, BasicLogEntry, TimeProps } from './util';

import './Nginx.css';

// http://nginx.org/en/docs/ngx_core_module.html#error_log
// debug, info, notice, warn, error, crit, alert, or emerg
const statusLevelMap = {
	emerg: 'error',
	alert: 'error',
	crit: 'error',
	error: 'error',
	warn: 'warning',
	notice: 'notice',
	info: 'log',
	debug: 'log',
};

type ParsedEntry = BasicLogEntry & {
	parsed: {
		level: string,
		message: string,
		process: string,
		thread: string,
		conn: string,
	},
};

const COLUMNS: Columns<ParsedEntry> = {
	date: {
		title: 'Date',
		className: 'ApplicationLogs-Nginx__date',
	},
	thread: {
		title: 'PID#TID',
		className: 'ApplicationLogs-Nginx__process',
		sort: [
			i => i.parsed.thread,
		],
	},
	message: {
		title: 'Message',
		className: 'ApplicationLogs-Nginx__message',
		sort: [
			i => i.parsed.message,
		],
	},
};

const parseLogLine = ( line: string ) => {
	// Strip the timestamp.
	const rest = line.replace( /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2} */, '' );

	// Extract the parts.
	// https://github.com/phusion/nginx/blob/09f16e4f8e52610bfe645ce4a720124ecb2e1cbf/src/core/ngx_log.c#L105-L113
	const parsed = rest.match(
		/^\[(?<level>\w+)\] (?<process>\d+)#(?<thread>\d+): (?<conn>\*\d+ )(?<msg>.+)?$/ms
	);

	if ( ! parsed ) {
		return {
			level: '',
			process: '',
			thread: '',
			conn: '',
			message: rest,
		};
	}

	return {
		level: parsed.groups?.level || '',
		process: parsed.groups?.process || '',
		thread: parsed.groups?.thread || '',
		conn: parsed.groups?.conn || '',
		message: parsed.groups?.msg || '',
	};
};

interface LogTableProps {
	application: ApplicationResponse,
	data: BasicLogEntry[],
}

function LogTable( props: LogTableProps ) {
	if ( ! props.data.length ) {
		return (
			<NoItemsFoundBlock
				message="🌴 Observe a beautiful, blank, empty paradise, free of distraction, worries of the world, and log entries. (Zero results found.)"
			/>
		);
	}
	const parsed: ParsedEntry[] = props.data.map( item => ( {
		...item,
		parsed: parseLogLine( item.message ),
	} ) );

	return (
		<SortableTable<ParsedEntry>
			className="ApplicationLogs-Nginx__table"
			columns={ COLUMNS }
			data={ parsed }
			defaultReverse
			defaultSort="date"
			isDense
		>
			{ item => (
				<tr
					key={ item.id }
				>
					<td className="ApplicationLogs-Nginx__date">
						<Timestamp
							date={ new Date( item.date ) }
						/>

						{ item.parsed.level && (
							<Level
								level={ statusLevelMap[ item.parsed.level as keyof typeof statusLevelMap ] as React.ComponentProps<typeof Level>['level'] || 'unknown' }
								text={ item.parsed.level }
								title={ item.parsed.level }
							/>
						) }
					</td>
					<td className="ApplicationLogs-Nginx__process">
						<abbr
							title={ `Process ${ item.parsed.process }` }
						>
							{ item.parsed.process }
						</abbr>
						{ '#' }
						<abbr
							title={ `Thread ${ item.parsed.thread }` }
						>
							{ item.parsed.thread }
						</abbr>

						{ item.parsed.conn && (
							<abbr
								className="ApplicationLogs-Nginx__conn"
								title={ `Connection ${ item.parsed.conn }` }
							>
								{ item.parsed.conn }
							</abbr>
						) }
					</td>
					<td className="ApplicationLogs-Nginx__message">
						{ item.parsed.message }
					</td>
				</tr>
			) }
		</SortableTable>
	);
}

type ConnectedLogsProps = Filter & {
	application: ApplicationResponse,
};
type ConnectedLogsData = {
	logs: BasicLogEntry[],
};
const logConnect = withApiData<ConnectedLogsData, ConnectedLogsProps>(
	props => ( {
		logs: `/stack/applications/${ props.application.id }/logs/nginx?after=${ props.after }&before=${ props.before }&search=${ props.search }`,
	} )
);
const ConnectedLogs = logConnect( props => (
	<ApplicationLogsWarning
		logs={ props.logs }
		renderLogs={ ( logs: BasicLogEntry[] ) => (
			<LogTable
				application={ props.application }
				data={ logs }
			/>
		) }
	/>
) );
type OwnProps = TimeProps & {
	application: ApplicationResponse,
	onTimeUpdate( t: any ): void,
};

type AllProps = OwnProps;

export default class NginxLogs extends React.Component<AllProps, Filter> {
	apiData: typeof ConnectedLogs['prototype'] | null = null;

	constructor( props: AllProps ) {
		super( props );
		this.state = {
			after: this.props.after || '4 hours ago',
			before: this.props.before || 'now',
			search: '',
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}

	onChangeFilter = ( filter: Filter ) => {
		this.apiData?.onInvalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	};

	render() {
		return (
			<div className="ApplicationLogs-Nginx">
				<Header
					after={ this.state.after }
					before={ this.state.before }
					search={ this.state.search }
					onChangeFilter={ this.onChangeFilter }
				/>

				<ConnectedLogs
					ref={ ref => this.apiData = ref }
					after={ this.state.after }
					application={ this.props.application }
					before={ this.state.before }
					search={ this.state.search }
				/>
			</div>
		);
	}
}
