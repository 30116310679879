import React, { Fragment } from 'react';

import IpAddressInformation from '../IpAddressInformation';

import { Subsegment } from './types';

import './Request.css';

type HeadersMap = {
	[ k: string ]: string,
}

const serverToHeaders = ( server: { [ k: string ]: string } ) => {
	return Object.keys( server ).reduce<HeadersMap>( ( headers, key ) => {
		if ( ! key.startsWith( 'HTTP_' ) ) {
			return headers;
		}

		const realKey = key.substr( 5 ).split( '_' ).map( word => word.substr( 0, 1 ).toUpperCase() + word.substr( 1 ).toLowerCase() ).join( '-' );
		headers[ realKey ] = server[ key ];
		return headers;
	}, {} );
};

const parseResponseHeaders = ( headers: string[] ) => {
	return headers.reduce<HeadersMap>( ( headers, header ) => {
		// Split based on : (manually, as .split can't limit)
		const sep = header.indexOf( ':' );
		const key = header.substr( 0, sep ).trim();
		const value = header.substr( sep + 1 ).trim();
		if ( headers[ key ] ) {
			headers[ key ] += ', ' + value;
		}
		headers[ key ] = value;
		return headers;
	}, {} );
};

function Headers( props: { headers: HeadersMap } ) {
	const keys = Object.keys( props.headers ).sort();
	return (
		<ul className="XRayTrace-Request__headers">
			{ keys.map( key => (
				<li
					key={ key }
					className="XRayTrace-Request__header"
				>
					<span className="XRayTrace-Request__header-key">{ key }</span>
					<span className="XRayTrace-Request__header-value">{ props.headers[ key ] }</span>
				</li>
			) ) }
		</ul>
	);
}

interface Props {
	main: Subsegment;
}

export default function Request( props: Props ) {
	const { main } = props;

	if ( ! main.http || ! main.http.request ) {
		return null;
	}

	// Grab the $_SERVER data if we can.
	const server = props.main.metadata?.$_SERVER;

	return (
		<div className="XRayTrace-Request">
			<h4>Client IP</h4>
			<p>{ main.http.request.client_ip }</p>
			<IpAddressInformation ipAddress={ main.http.request.client_ip } />

			{ server && (
				<>
					<h4>Request Headers</h4>
					<Headers
						headers={ serverToHeaders( server ) }
					/>
				</>
			) }
			{ props.main.metadata?.response?.headers && (
				<>
					<h4>Response Headers</h4>
					<Headers
						headers={ parseResponseHeaders( props.main.metadata.response.headers ) }
					/>
				</>
			) }

			<h4>Metadata</h4>
			<pre
				className="overflow-y-auto"
			>
				{ JSON.stringify( main.metadata, null, 4 ) }
			</pre>

			{ main.annotations && (
				<>
					<h4>Annotations</h4>
					<pre
						className="overflow-y-auto"
					>
						{ JSON.stringify( main.annotations, null, 4 ) }
					</pre>
				</>
			) }
		</div>
	);
}
