import mean from 'lodash/mean';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import qs from 'qs';
import React, { useState } from 'react';

import { ConnectedProps, withApiData } from '../../lib/with-api-data';
import ErrorBlock from '../ErrorBlock';
import Infotip from '../Infotip';
import useTooltip, { Tooltip } from '../useTooltip';

import ApdexChart from './ApdexChart';
import ErrorsChart from './ErrorsChart';
import MainChart from './MainChart';
import RequestsChart from './RequestsChart';
import { formatLatency, formatPercentage, queryForTimeFilter, StatEntry, TimeFilter } from './util';

type AnnotationProps = {
	children: React.ReactNode,
	className?: string,
	title: React.ReactNode
};

function Annotation( props: AnnotationProps ) {
	const { setReferenceElement, TooltipProps } = useTooltip();
	const [ showing, setShowing ] = useState<boolean>( false );

	return (
		<>
			<p
				ref={ setReferenceElement }
				className={ `underline decoration-dotted ${ props.className || '' }` }
				onMouseOut={ () => setShowing( false ) }
				onMouseOver={ () => setShowing( true ) }
			>
				{ props.children }
			</p>

			<Tooltip
				{ ...TooltipProps }
				className="pointer-events-none"
				visible={ showing }
			>
				{ props.title }
			</Tooltip>
		</>
	);
}

type CardProps = {
	annotation?: React.ReactNode,
	children: React.ReactNode,
	className?: string,
	title: React.ReactNode,
};

function Card( props: CardProps ) {
	return (
		<div className={ `rounded ring-1 ring-gray-200 p-3 ${ props.className || '' }` }>
			<header className="flex mb-4">
				<h3 className="flex-grow flex-shrink-0 font-medium text-base">{ props.title }</h3>
				{ props.annotation && (
					<div className="underline decoration-dotted">
						{ props.annotation }
					</div>
				) }
			</header>
			<div>
				{ props.children }
			</div>
		</div>
	);
}

type DataTypes = {
	statistics: StatEntry[],
}

interface OwnProps {
	application: string;
	time: TimeFilter,
}

export class Statistics extends React.Component<OwnProps & ConnectedProps<typeof connect>> {
	// Allow refreshing data via refs.
	onRefresh() {
		this.props.refreshData();
	}

	render() {
		const { statistics } = this.props;
		if ( statistics.error ) {
			return (
				<ErrorBlock
					message={ statistics.error.message }
				/>
			);
		}

		const statsData = statistics.isLoading ? [] : ( statistics.data || [] );
		if ( ! statistics.isLoading && ! statsData.length ) {
			return null;
		}

		const sortedStats = sortBy( statsData, i => i.time );
		const totalRequests = sum( sortedStats.map( entry => entry.requests ) );

		return (
			<div className="XRayOverview-Statistics grid grid-cols-12 gap-4">
				<Card
					annotation={ (
						<Annotation
							className="text-base"
							title="Average total response time"
						>
							{ statsData.length ? formatLatency( mean( sortedStats.map( entry => entry.latency ) ) ) : '' }
						</Annotation>
					) }
					className="col-span-8"
					title={ (
						<>
							Response Time

							<Infotip className="text-gray-700/50">
								Measured response time from PHP.
							</Infotip>
						</>
					) }
				>
					<MainChart
						data={ sortedStats }
						isLoading={ statistics.isLoading }
					/>
				</Card>
				<Card
					annotation={ (
						<Annotation
							title="Average Apdex score and response time target"
						>
							{ totalRequests > 0 ? round( mean( sortedStats.map( entry => entry.apdex.value ) ), 2 ) : '0.0' } [0.4]
						</Annotation>
					) }
					className="col-span-4"
					title={ (
						<>
							Apdex

							<Infotip className="text-gray-700/50">
								Apdex is an industry-standard to measure user satisfaction with response times.
							</Infotip>
						</>
					) }
				>
					<ApdexChart
						data={ sortedStats }
						isLoading={ statistics.isLoading }
					/>
				</Card>

				<Card
					annotation={ (
						<Annotation
							title="Average requests per minute"
						>
							{ statsData.length ?  round( mean( sortedStats.map( entry => entry.requests ) ), 1 ) : '…' } rpm
						</Annotation>
					) }
					className="col-span-8"
					title={ (
						<>
							Requests

							<Infotip className="text-gray-700/50">
								HTTP requests which invoke PHP. Cached requests or non-PHP requests are not counted.
							</Infotip>
						</>
					) }
				>
					<RequestsChart
						data={ sortedStats }
						isLoading={ statistics.isLoading }
					/>
				</Card>

				<Card
					annotation={ (
						<Annotation
							title="Average request rate"
						>
							{ totalRequests > 0 ? formatPercentage( mean( sortedStats.map( entry => entry.errors / entry.requests ) ) ) : '' }
						</Annotation>
					) }
					className="col-span-4"
					title={ (
						<>
							Errors

							<Infotip className="text-gray-700/50">
								Percentage of requests with faults (fatal errors) and general errors (including warnings and notices).
							</Infotip>
						</>
					) }
				>
					<ErrorsChart
						data={ sortedStats }
						isLoading={ statistics.isLoading }
					/>
				</Card>
			</div>
		);
	}
}

const connect = withApiData<DataTypes, OwnProps>( props => {
	const queryParams = qs.stringify( queryForTimeFilter( props.time ) );
	return {
		statistics: `/stack/applications/${ props.application }/xray/statistics?${ queryParams }`,
	};
} );

export default connect( Statistics );
