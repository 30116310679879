import random from 'lodash/random';
import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import { defaultAPI, cookieAuthApi, RestApiError } from '../api';
import { ReactComponent as LogoSVG } from '../images/logo.svg';
import loginImages from '../lib/login';

import Button from './Button';
import ErrorBlock from './ErrorBlock';

import './Login.css';

type Step = 'login' | 'mfa';

const mfaLabels = {
	backup_codes: 'Enter a backup code',
	totp: 'Use your authentication app',
	email: 'Use your email two-factor code',
};

interface MfaChallenge {
	'2fa_providers': Array<keyof typeof mfaLabels>,
	'2fa_provider_primary': keyof typeof mfaLabels,
}

interface MfaData {
	provider: keyof typeof mfaLabels,
	value: any,
}

interface MfaProps {
	challenge: MfaChallenge,
	loading: boolean,
	onCancel(): void,
	onSubmit( data: MfaData ): void,
}

function MfaForm( props: MfaProps ) {
	const providers = props.challenge['2fa_providers'];
	const [ selected, setSelected ] = useState<keyof typeof mfaLabels>( props.challenge['2fa_provider_primary'] || providers[0] );
	const [ value, setValue ] = useState<any>( null );

	const onSubmit = ( e: FormEvent<HTMLFormElement> ) => {
		e.preventDefault();
		props.onSubmit( {
			provider: selected,
			value,
		} );
	};

	return (
		<form
			className="mt-10 space-y-6"
			onSubmit={ onSubmit }
		>
			<h2 className="mt-8 text-2xl leading-9 tracking-tight text-gray-900">
				Two-factor authentication
			</h2>

			{ selected === 'totp' ? (
				<div className="space-y-4">
					<p className="mt-6 text-sm leading-6 text-gray-500">
						Open your two-factor authentication app and enter the code below.
					</p>
					<div>
						<label
							className="block text-sm font-medium leading-6 text-gray-900"
							htmlFor="token"
						>
							Authentication code
						</label>
						<input
							autoFocus
							className="block font-mono w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							id="token"
							pattern="[0-9]{6}"
							placeholder="XXXXXX"
							required
							type="text"
							onChange={ e => setValue( e.target.value ) }
						/>
					</div>
					<div className="space-x-2">
						<Button
							className="w-full justify-center"
							type="submit"
							variant="primary"
						>
							Log in
						</Button>
					</div>
				</div>
			) : selected === 'backup_codes' ? (
				<div className="space-y-4">
					<p className="mt-6 text-sm leading-6 text-gray-500">
						If you are unable to access your two-factor
						authentication device, enter one of your recovery
						codes to verify your identity.
					</p>
					<div>
						<label
							autoFocus
							className="block text-sm font-medium leading-6 text-gray-900"
							htmlFor="token"
						>
							Backup code
						</label>
						<input
							className="block font-mono w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							id="token"
							pattern="[0-9]{8}"
							placeholder="XXXXXXXX"
							required
							type="text"
							onChange={ e => setValue( e.target.value ) }
						/>
					</div>
					<div className="space-x-2">
						<Button
							className="w-full justify-center"
							disabled={ props.loading }
							type="submit"
							variant="primary"
						>
							Log in
						</Button>
					</div>
				</div>
			) : selected === 'email' ? (
				<div className="space-y-4">
					<p className="mt-6 text-sm leading-6 text-gray-500">
						A verification code has been sent to the email address associated with your account.
					</p>
					<div>
						<label
							autoFocus
							className="block text-sm font-medium leading-6 text-gray-900"
							htmlFor="token"
						>
							Verification code
						</label>
						<input
							className="block font-mono w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							id="token"
							pattern="[0-9]{8}"
							placeholder="XXXXXXXX"
							required
							type="text"
							onChange={ e => setValue( e.target.value ) }
						/>
					</div>
					<div className="space-x-2">
						<Button
							className="w-full justify-center"
							disabled={ props.loading }
							type="submit"
							variant="primary"
						>
							Log in
						</Button>
					</div>
				</div>
			) : (
				<div>
					<p className="mt-6 text-sm leading-6 text-gray-500">
						Whoops, your two-factor provider does not seem to be supported on this screen.
						Try the
						{ ' ' }
						<a
							className="font-semibold text-blue-600 hover:text-blue-900"
							href="/wp-login.php?action=login"
						>
							legacy login screen
						</a>
						{ ' ' }
						instead.
					</p>
				</div>
			) }

			<div className="space-y-4">
				<div className="relative">
					<div aria-hidden="true" className="absolute inset-0 flex items-center">
						<div className="w-full border-t border-gray-200"></div>
					</div>
					<div className="relative flex justify-center text-sm font-medium leading-6">
						<span className="bg-white px-6 text-gray-600">Or select an alternative method</span>
					</div>
				</div>
				<ul className="space-y list-disc pl-4">
					{ providers.filter( p => p !== selected ).map( provider => (
						<li key={ provider }>
							<button
								className="text-blue-600 hover:text-blue-900 focus:text-blue-900"
								onClick={ () => setSelected( provider ) }
							>
								{ mfaLabels[ provider ] || `Use other method (${ provider })` }
							</button>
						</li>
					) ) }
					<li>
						<button
							className="text-blue-600 hover:text-blue-900 focus:text-blue-900"
							onClick={ props.onCancel }
						>
							Cancel
						</button>
					</li>
				</ul>
			</div>
		</form>
	);
}

const pickImage = () => loginImages[ random( 0, loginImages.length - 1 ) ];

interface LoginRequest {
	username: string,
	password: string,
	auth_nonce?: string,
	'2fa'?: MfaData,
}

export function Layout( props: { children: React.ReactNode } ) {
	const [ image ] = useState<string>( pickImage() );

	return (
		<div className="flex !flex-row h-full items-stretch">
			<div
				className="flex flex-1 flex-col overflow-y-auto justify-center px-4 py-4 md:py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
			>
				<div className="h-full mx-auto w-full max-w-sm lg:w-96">
					<div>
						<LogoSVG
							className="mt-8 h-10 w-auto"
						/>
					</div>

					{ props.children }
				</div>
			</div>
			<div className="relative hidden flex-1 lg:block">
				<img
					alt=""
					className="absolute inset-0 h-full w-full object-cover"
					src={ image }
				/>
			</div>
		</div>
	);
}

interface Props {
	onLogin?: ( response: any ) => null,
}

export default function Login( props: Props ) {
	const [ step, setStep ] = useState<Step>( 'login' );
	const [ email, setEmail ] = useState( '' );
	const [ password, setPassword ] = useState( '' );
	const [ mfaChallenge, setMfaChallenge ] = useState<MfaChallenge | null>( null );
	const [ errorMessage, setErrorMessage ] = useState( '' );
	const [ loading, setLoading ] = useState( false );

	let session: { nonce: string }|null = null;

	const handleSubmit = ( event: FormEvent<HTMLFormElement> ) => {
		event.preventDefault();
		onLogin();
	};

	const onLogin = async ( mfaData: MfaData | null = null ) => {
		setErrorMessage( '' );
		try {
			const data: LoginRequest = {
				username: email,
				password: password,
				auth_nonce: window.VantageInitData?.auth_nonce,
				'2fa': mfaData || undefined,
			};
			// make sure nonce is not set on the API when sending requests.
			( defaultAPI as cookieAuthApi ).nonce = undefined;
			setLoading( true );
			session = await defaultAPI.post( '/sessions/v0/sessions', data );
		} catch ( e ) {
			if ( e instanceof RestApiError ) {
				if ( e.errors['2fa_required'] ) {
					const challenge = e.errors['2fa_required'].data as MfaChallenge;
					setStep( 'mfa' );
					setMfaChallenge( challenge );
				} else if ( e.code === 'rest_cookie_invalid_nonce' ) {
					// Auth nonce is invalid.
					setErrorMessage( 'Login nonce is invalid, it may have expired. Please reload the page and try again.' );
					return;
				}
				// Show the message from the first error that is not 2fa_required
				Object.entries( e.errors ).forEach( ( [ code, error ] ) => {
					if ( code === '2fa_required' ) {
						return;
					}
					setErrorMessage( error.message );
				} );
			}
		} finally {
			setLoading( false );
		}

		if ( props.onLogin && session ) {
			props.onLogin( session );
		}

	};

	return (
		<Layout>
			{ errorMessage && (
				<div className="mt-4">
					<ErrorBlock
						message={ <span dangerouslySetInnerHTML={ { __html: errorMessage } }></span> }
					/>
				</div>
			) }

			{ step === 'login' ? (
				<form
					className="mt-10 space-y-6"
					onSubmit={ handleSubmit }
				>
					<h2 className="mt-8 text-2xl leading-9 tracking-tight text-gray-900">
						Sign in to the Altis Dashboard
					</h2>
					<p className="mt-6 text-sm leading-6 text-gray-500">
						Welcome to the Altis Dashboard. We're glad you're here.
					</p>
					<div>
						<label
							className="block text-sm font-medium leading-6 text-gray-900"
							htmlFor="email"
						>
							Email
						</label>
						<input
							autoFocus
							className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							id="email"
							required
							type="text"
							onChange={ e => setEmail( e.target.value ) }
						/>
					</div>
					<div>
						<label
							className="block text-sm font-medium leading-6 text-gray-900"
							htmlFor="password"
						>
							Password
						</label>
						<input
							className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							id="password"
							required
							type="password"
							onChange={ e => setPassword( e.target.value ) }
						/>
					</div>
					<p className="text-sm leading-6 my-2">
						<a
							className="font-semibold text-blue-600 hover:text-blue-900"
							href="/wp-login.php?action=lostpassword"
						>
							Lost your password?
						</a>
					</p>

					<div className="space-x-2">
						<Button
							className="w-full justify-center"
							disabled={ loading }
							type="submit"
							variant="primary"
						>
							Log in
						</Button>
					</div>
				</form>
			)  : mfaChallenge !== null ? (
				<MfaForm
					challenge={ mfaChallenge }
					loading={ loading }
					onCancel={ () => {
						setStep( 'login' );
						setEmail( '' );
						setPassword( '' );
						setMfaChallenge( null );
					} }
					onSubmit={ onLogin }
				/>
			) : (
				<p>Unknown error occurred. Try refreshing.</p>
			) }

			{ step === 'login' && (
				<>
					<div className="mt-6 text-sm leading-6 text-gray-500">
						<h3 className="text-gray-800">Need access?</h3>
						<p>Altis Cloud customers should contact their account manager for access to the Altis Dashboard.</p>
					</div>
					<div className="mt-6 text-sm leading-6 text-gray-500">
						<h3 className="text-gray-800">Altis Accelerate</h3>
						<p>
							Looking for Altis Accelerate? Access the
							{ ' ' }
							<Link className="text-blue-600 hover:text-blue-900" to="/accelerate/">Accelerate Dashboard</Link> here.
						</p>
					</div>
				</>
			) }

			{ errorMessage !== '' && (
				<div className="mt-4 p-4 text-sm rounded-md bg-yellow-50 space-y-4">
					<p>If you are unable to access the Altis Dashboard, you can contact support for non-urgent problems at <code>support@altis-dxp.com</code></p>
					<p>For urgent issues such as your site being down, contact <code>sysops@altis-dxp.com</code></p>
				</div>
			) }
		</Layout>
	);
}
