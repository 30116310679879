import React, { Component } from 'react';

import { PostResponse } from '../../api';
import { ConnectedProps, withApiData } from '../../lib/with-api-data';
import ErrorBlock from '../ErrorBlock';
import Loader from '../Loader';
import PageTitle from '../PageTitle';

import Content from './Content';
import Header from './Header';

type Props = {
	showExcerpt: boolean,
	postId: number,
};
type DataTypes = {
	postObject: PostResponse,
};

export class BlogSingle extends Component<Props & ConnectedProps<typeof connect>> {
	render() {
		const {
			postId,
			postObject,
			showExcerpt,
		} = this.props;
		return (
			<div className="Blog-Single">
				<PageTitle
					title="Blog"
				/>

				<Header
					withBack
				/>

				{ postObject.isLoading ? (
					<PageTitle title="Loading…">
						<Loader />
					</PageTitle>
				) : postObject.error ? (
					<ErrorBlock message={ postObject.error.message } />
				) : ! postObject.data ? (
					<p>Could not find a blog post with ID <code>{ postId }</code></p>
				) : (
					<PageTitle title={ postObject.data.title.rendered }>
						<Content
							isSingle
							postObject={ postObject.data }
							showExcerpt={ showExcerpt }
						/>
					</PageTitle>
				) }
			</div>
		);
	}
}

const connect = withApiData<DataTypes, Props>( props => ( {
	postObject: `wp/v2/posts/${ props.postId }?_embed=author`,
} ) );
export default connect( BlogSingle );
