import padStart from 'lodash/padStart';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import React from 'react';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import { FormattedDate, FormattedNumber } from 'react-intl';

import { ConnectedProps, withApiData } from '../../lib/with-api-data';
import { InstanceResponse } from '../../types/rest-api';
import PageTitle from '../PageTitle';

import './Reports.css';

type ReportResponse = {
	year: number,
	month: number,
	topline: {
		response_time: number,
		uptime: number,
		views: number,
	},
	_links: {
		'https://hm-stack.hm/report': {
			href: string,
		}[],
	}
}[];

const getDate = ( year: number, month: number ) => new Date( year, month - 1 );

const ResponseTime = ( props: { value: number } ) => {
	if ( props.value > 1 ) {
		return (
			<>
				<FormattedNumber
					value={ round( props.value, 2 ) }
				/>
				{ 's' }
			</>
		);
	}

	return (
		<>
			<FormattedNumber
				value={ round( props.value * 1000, 0 ) }
			/>
			{ 'ms' }
		</>
	);
};

type UptimeProps = {
	precise?: boolean,
	value: number,
}
const Uptime = ( props: UptimeProps ) => {
	const threshold = props.precise ? 99.9999 : 99.995;
	const percent = props.value * 100;
	if ( percent >= threshold ) {
		return <>100%</>;
	}

	return (
		<>
			<FormattedNumber
				value={ round( percent, props.precise ? 3 : 2 ) }
			/>
			{ '%' }
		</>
	);
};

const Views = ( props: { value: number } ) => {
	if ( props.value > 1e6 ) {
		const millions = round( props.value / 1e6, 1 );
		return (
			<>
				<FormattedNumber
					value={ millions }
				/>
				{ 'M' }
			</>
		);
	}
	if ( props.value > 1e6 ) {
		const thou = round( props.value / 1e3, 1 );
		return (
			<>
				<FormattedNumber
					value={ thou }
				/>
				{ 'K' }
			</>
		);
	}

	return (
		<FormattedNumber
			value={ props.value }
		/>
	);
};

interface Props {
	instance: InstanceResponse,
	instanceLoading: boolean,
}

type DataTypes = {
	reports: ReportResponse,
};

function Reports( props: Props & ConnectedProps<typeof connect> ) {
	if ( ! props.reports || props.reports.isLoading ) {
		return (
			<p>Loading…</p>
		);
	}

	const trackClick = () => {
		window.analytics.track( 'instance_monthly_report_downloaded', {
			instance: props.instance.id,
		} );
	};

	const sorted = reverse( sortBy( props.reports.data, report => `${ report.year }-${ padStart( '' + report.month, 2, '0' ) }` ) );
	return (
		<div className="Instance-Reports">
			<PageTitle title="Reports" />
			<h1>Reports</h1>
			<p>Altis provides a monthly report of your total views, uptime, and average response time. Previous reports are listed below.</p>
			<p>Note that monthly reports may take a few days after the start of the month to be available.</p>
			<table className="Instance-Reports__table">
				<thead>
					<tr>
						<th className="Instance-Reports__date">Date</th>
						<th className="Instance-Reports__views">Views</th>
						<th className="Instance-Reports__uptime">Uptime</th>
						<th className="Instance-Reports__responsetime">Average Response Time</th>
					</tr>
				</thead>
				<tbody>
					{ sorted.map( report => (
						<tr
							key={ `${ report.year }-${ report.month }` }
						>
							<td className="Instance-Reports__date">
								<a
									href={ report._links['https://hm-stack.hm/report'][0].href }
									rel="noopener noreferrer"
									target="_blank"
									onClick={ trackClick }
								>
									<FormattedDate
										month="long"
										value={ getDate( report.year, report.month ) }
										year="numeric"
									/>
									{ ' ' }
									<ExternalLinkIcon
										size={ 14 }
									/>
								</a>
							</td>
							<td className="Instance-Reports__views">
								<Views
									value={ report.topline.views }
								/>
							</td>
							<td className="Instance-Reports__uptime">
								<Uptime
									value={ report.topline.uptime }
								/>
							</td>
							<td className="Instance-Reports__responsetime">
								<ResponseTime
									value={ report.topline.response_time }
								/>
							</td>
						</tr>
					) ) }
					{ sorted.length < 1 && (
						<tr>
							<td
								className="Instance-Reports__none"
								colSpan={ 4 }
							>
								<p>There are no historical reports available
									yet. If you believe this is a mistake,
									please contact your account manager.</p>
							</td>
						</tr>
					) }
				</tbody>
			</table>
		</div>
	);
}

const connect = withApiData<DataTypes, Props>( props => {
	if ( props.instanceLoading || ! props.instance ) {
		return {
			reports: undefined,
		};
	}

	return {
		reports: `/stack/instances/${ props.instance.id }/reports?_embed`,
	};
} );

export default connect( Reports );
