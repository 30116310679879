import React from 'react';

import { ApplicationResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import ApplicationLogsWarning from '../ApplicationLogsWarning';
import NoItemsFoundBlock from '../NoItemsFoundBlock';
import SortableTable, { Columns } from '../SortableTable';

import Header from './Header';
import Timestamp from './Timestamp';
import { Filter, BasicLogEntry, TimeProps } from './util';

import './DatabaseGeneral.css';

type ParsedEntry = BasicLogEntry & {
	parsed: {
		command: string,
		message: string,
		thread: string,
	},
};

const COLUMNS: Columns<ParsedEntry> = {
	date: {
		title: 'Date',
		className: 'ApplicationLogs-Database__date',
	},
	thread: {
		title: 'Thread',
		className: 'ApplicationLogs-Database__thread',
		sort: [
			i => i.parsed.thread,
		],
	},
	command: {
		title: 'Command',
		className: 'ApplicationLogs-Database__command',
		sort: [
			i => i.parsed.command,
		],
	},
	message: {
		title: 'Query',
		className: 'ApplicationLogs-Database__message',
		sort: [
			i => i.parsed.message,
		],
	},
};

const parseLogLine = ( line: string ) => {
	// Strip the timestamp.
	const rest = line.replace( /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z ?/, '' );

	// Extract the parts.
	const parsed = rest.match(
		/^(?<thread>\d+) (?<cmd>[\w ]+)(\t(?<msg>.*))?$/ms
	);

	if ( ! parsed ) {
		console.log( rest );
		return {
			thread: '',
			command: '',
			message: rest,
		};
	}

	return {
		thread: parsed.groups?.thread || '',
		command: parsed.groups?.cmd || '',
		message: parsed.groups?.msg || '',
	};
};

interface LogTableProps {
	application: ApplicationResponse,
	data: BasicLogEntry[],
}

function LogTable( props: LogTableProps ) {
	if ( ! props.data.length ) {
		return (
			<NoItemsFoundBlock
				message="🌴 Observe a beautiful, blank, empty paradise, free of distraction, worries of the world, and log entries. (Zero results found.)"
			/>
		);
	}
	const parsed: ParsedEntry[] = props.data.map( item => ( {
		...item,
		parsed: parseLogLine( item.message ),
	} ) );

	return (
		<SortableTable<ParsedEntry>
			className="ApplicationLogs-Database__table"
			columns={ COLUMNS }
			data={ parsed }
			defaultReverse
			defaultSort="date"
			isDense
			isFixed
		>
			{ item => (
				<tr
					key={ item.id }
				>
					<td className="ApplicationLogs-Database__date">
						<Timestamp
							date={ new Date( item.date ) }
						/>
					</td>
					<td className="ApplicationLogs-Database__thread">
						{ item.parsed.thread }
					</td>
					<td className="ApplicationLogs-Database__command">
						{ item.parsed.command }
					</td>
					<td className="ApplicationLogs-Database__message">
						{ item.parsed.message }
					</td>
				</tr>
			) }
		</SortableTable>
	);
}

type ConnectedLogsProps = Filter & {
	application: ApplicationResponse,
};
type ConnectedLogsData = {
	logs: BasicLogEntry[],
};
const logConnect = withApiData<ConnectedLogsData, ConnectedLogsProps>(
	props => ( {
		logs: `/stack/applications/${ props.application.id }/logs/db-general?after=${ props.after }&before=${ props.before }&search=${ props.search }`,
	} )
);
const ConnectedLogs = logConnect( props => (
	<ApplicationLogsWarning
		logs={ props.logs }
		renderLogs={ ( logs: BasicLogEntry[] ) => (
			<LogTable
				application={ props.application }
				data={ logs }
			/>
		) }
	/>
) );
type OwnProps = TimeProps & {
	application: ApplicationResponse,
	onTimeUpdate( t: any ): void,
};

type AllProps = OwnProps;

export default class DatabaseGeneral extends React.Component<AllProps, Filter> {
	apiData: typeof ConnectedLogs['prototype'] | null = null;

	constructor( props: AllProps ) {
		super( props );
		this.state = {
			after: this.props.after || '4 hours ago',
			before: this.props.before || 'now',
			search: '',
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}

	onChangeFilter = ( filter: Filter ) => {
		this.apiData?.onInvalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	};

	render() {
		return (
			<div className="ApplicationLogs-DatabaseGeneral">
				<Header
					after={ this.state.after }
					before={ this.state.before }
					search={ this.state.search }
					onChangeFilter={ this.onChangeFilter }
				/>

				<ConnectedLogs
					ref={ ref => this.apiData = ref }
					after={ this.state.after }
					application={ this.props.application }
					before={ this.state.before }
					search={ this.state.search }
				/>
			</div>
		);
	}
}
