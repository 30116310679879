import { Switch } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import React, { useRef, useState } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import EyeIcon from 'react-feather/dist/icons/eye';
import LockIcon from 'react-feather/dist/icons/lock';
import PlusCircleIcon from 'react-feather/dist/icons/plus-circle';
import { NavLink } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import { ConnectedProps, withApiData } from '../../lib/with-api-data';
import Button from '../Button';
import ErrorBlock from '../ErrorBlock';
import FormattedRelativeTime from '../FormattedRelativeTime';
import SettingsGroup from '../SettingsGroup';

type VariableType = 'app' | 'build';

interface Variable {
	name: string,
	value: string | null,
	secret: boolean,
	modified: string,
}

type VariableFormProps = {
	existing?: Variable,
	loading: boolean,
	onCancel: () => void,
	onSubmit: ( name: string, value: string, secret: boolean ) => void,
};

function VariableForm( props: VariableFormProps ) {
	const [ name, setName ] = useState<string>( props.existing?.name || '' );
	const [ value, setValue ] = useState<string>( props.existing?.value || '' );
	const [ secret, setSecret ] = useState<boolean>( props.existing?.secret || false );
	const nameRef = useRef<HTMLInputElement>( null );
	const secretRef = useRef<HTMLTextAreaElement>( null );

	const nameValid = nameRef.current?.validity.valid;
	const secretValid = secretRef.current?.validity.valid;

	const onSubmit = () => {
		if ( ! nameValid || ! secretValid ) {
			return;
		}

		props.onSubmit( name, value, secret );
	};

	return (
		<tr>
			<td className="align-top max-w-0 pt-4 pb-8 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
				<label
					className="block text-sm font-medium leading-6 text-gray-900"
					htmlFor="var_name"
				>
					Name
				</label>
				<div className="mt-2">
					<div className="relative rounded-md shadow-sm">
						<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
							{ secret ? (
								<LockIcon
									className="h-4 w-4 text-gray-400"
								/>
							) : (
								<EyeIcon
									className="h-4 w-4 text-gray-400"
								/>
							) }
						</div>
						<input
							ref={ nameRef }
							className="block w-full rounded-md border-0 py-1.5 pl-10 font-mono text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							id="var_name"
							maxLength={ 100 }
							pattern="^[a-zA-Z0-9_]+$"
							placeholder="YOUR_VARIABLE"
							type="text"
							value={ name }
							onChange={ e => setName( e.target.value ) }
						/>
					</div>
				</div>
				{ nameValid === false && (
					<p className="mt-2 text-sm text-red-600">
						Names can only contain alphanumeric characters and underscores. Maximum 100 characters.
					</p>
				) }
			</td>
			<td className="hidden align-top px-3 py-4 text-sm text-gray-500 lg:table-cell">
				<label
					className="block text-sm font-medium leading-6 text-gray-900"
					htmlFor="var_value"
				>
					Value
				</label>
				<div className="mt-2 w-full">
					<textarea
						ref={ secretRef }
						className="block w-full rounded-md border-0 py-1.5 font-mono text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						id="var_value"
						maxLength={ 4096 }
						rows={ 8 }
						value={ value }
						onChange={ e => setValue( e.target.value ) }
					>
						{ value }
					</textarea>
				</div>
				{ secretValid === false && (
					<p className="mt-2 text-sm text-red-600">
						Invalid.
					</p>
				) }
				<Switch.Group as="div" className="flex items-center justify-between gap-x-4 my-3 max-w-[40rem]">
					<span className="flex flex-grow flex-col">
						<Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
							Mark as secret
						</Switch.Label>
						{ props.existing ? (
							<Switch.Description as="span" className="text-sm text-gray-400">
								(This can't be changed for existing variables.)
							</Switch.Description>
						) : (
							<Switch.Description as="span" className="text-sm text-gray-500">
								Use secrets for sensitive data like authentication tokens.
							</Switch.Description>
						) }
					</span>
					<Switch
						checked={ secret }
						className={ [
							secret ? 'bg-blue-600' : 'bg-gray-200',
							'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
						].filter( Boolean ).join( ' ' ) }
						disabled={ props.loading || props.existing !== undefined }
						onChange={ setSecret }
					>
						<span className="sr-only">Use setting</span>
						<span
							aria-hidden="true"
							className={ [
								secret ? 'translate-x-5' : 'translate-x-0',
								'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
							].filter( Boolean ).join( ' ' ) }
						>
							<span
								aria-hidden="true"
								className={ [
									secret ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
									'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
								].filter( Boolean ).join( ' ' ) }
							>
								<EyeIcon className="w-3 h-3 text-gray-400" />
							</span>
							<span
								aria-hidden="true"
								className={ [
									secret ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
									'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
								].filter( Boolean ).join( ' ' ) }
							>
								<LockIcon className="w-3 h-3 text-gray-400" />
							</span>
						</span>
					</Switch>
				</Switch.Group>
			</td>
			<td className="min-w-0 whitespace-nowrap py-4 align-top pl-3 pr-4 text-right text-sm font-medium sm:pr-0 space-x-4">
				<Button
					disabled={ ! nameValid || ! secretValid || props.loading }
					variant="primary"
					onClick={ onSubmit }
				>
					{ props.existing ? (
						props.loading ? 'Saving…' : 'Save'
					) : (
						props.loading ? 'Creating…' : 'Create'
					) }
				</Button>
				<Button
					disabled={ props.loading }
					onClick={ props.onCancel }
				>
					Cancel
				</Button>
			</td>
		</tr>
	);
}

type VariableTableProps = {
	creating: boolean,
	deleting: Variable | null,
	editing: Variable | null,
	saving: boolean,
	variables: Variable[],
	onCreate: ( name: string, value: string, secret: boolean ) => void,
	onEdit: ( name: string, value: string ) => void,
	onDelete: ( name: string ) => void,
	onSetDeleting: ( variable: Variable | null ) => void,
	onSetEditing: ( variable: Variable | null ) => void,
	onSetCreating: ( show: boolean ) => void,
};

function VariableTable( props: VariableTableProps ) {
	const { creating, editing, deleting, saving } = props;
	return (
		<table className="w-full min-w-full grid grid-cols-[minmax(200px,min-content)_auto_min-content]">
			<tbody className="contents [&_tr]:contents divide-y divide-gray-200 bg-white">
				{ creating ? (
					<VariableForm
						loading={ saving }
						onCancel={ () => props.onSetCreating( false ) }
						onSubmit={ props.onCreate }
					/>
				) : props.variables.length === 0 && (
					<tr>
						<td
							className="col-span-3 py-4 text-gray-500"
							colSpan={ 3 }
						>
							<button
								className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								type="button"
								onClick={ () => props.onSetCreating( true ) }
							>
								<PlusCircleIcon
									className="mx-auto h-12 w-12 text-gray-400"
								/>
								<span className="mt-2 block text-sm font-semibold text-gray-900">Create your first variable or secret</span>
							</button>
						</td>
					</tr>
				) }
				{ props.variables.map( variable => (
					editing === variable ? (
						<VariableForm
							existing={ variable }
							loading={ saving }
							onCancel={ () => props.onSetEditing( null ) }
							onSubmit={ props.onEdit }
						/>
					) : deleting === variable ? (
						<tr>
							<td
								className="col-span-3 sm:flex sm:flex-col sm:items-center px-8 py-4 bg-red-100 text-gray-500"
								colSpan={ 3 }
							>
								<div className="sm:flex sm:items-center sm:space-x-4">
									<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationIcon
											aria-hidden="true"
											className="h-6 w-6 text-red-600"
										/>
									</div>
									<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
										You are about to delete <code className="font-mono">{ variable.name }</code>. This action cannot be undone.
									</div>
								</div>

								<div className="mt-2 space-x-4">
									<Button
										variant="tertiary"
										onClick={ () => props.onSetDeleting( null ) }
									>
										Cancel
									</Button>
									<Button
										disabled={ saving }
										variant="destructive"
										onClick={ () => props.onDelete( variable.name ) }
									>
										{ saving ? 'Deleting…' : 'Delete' }
									</Button>
								</div>
							</td>
						</tr>
					) : (
						<tr key={ variable.name }>
							<td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
								<span className="font-mono truncate whitespace-nowrap">
									{ variable.secret ? (
										<LockIcon
											className="w-4 h-4 text-gray-400 inline-block -mt-1 mr-1"
										/>
									) : (
										<EyeIcon
											className="w-4 h-4 text-gray-400 inline-block -mt-1 mr-1"
										/>
									) }
									{ variable.name }
								</span>

								<dl className="text-xs text-gray-500 lg:hidden">
									<dt className="sr-only">Value</dt>
									<dd className="mt-1 font-mono">
										{ variable.secret ? '••••••' : variable.value }
									</dd>
									<dt className="">Last modified</dt>
									<dd className="mt-1 truncate text-gray-500 sm:hidden"><FormattedRelativeTime value={ variable.modified } /></dd>
								</dl>
							</td>
							<td className="px-3 py-4 text-sm text-gray-500 overflow-hidden">
								<p
									className="font-mono truncate w-full"
									title={ variable.secret ? '••••••' : variable.value || '' }
								>
									{ variable.secret ? '••••••' : variable.value }
								</p>
								<p className="text-xs text-gray-500">Last modified <FormattedRelativeTime value={ variable.modified } /></p>
							</td>
							<td className="min-w-0 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 space-x-4">
								<Button
									disabled={ editing !== null }
									size="s"
									onClick={ () => props.onSetEditing( variable ) }
								>
									Edit
								</Button>
								<Button
									disabled={ editing !== null }
									size="s"
									variant="tertiary"
									onClick={ () => props.onSetDeleting( variable ) }
								>
									Delete
								</Button>
							</td>
						</tr>
					)
				) ) }
			</tbody>
		</table>
	);
}

interface DataTypes {
	variables: Variable[],
}

interface OwnProps {
	application: ApplicationResponse,
	type?: VariableType,
}

export function Variables( props: OwnProps & ConnectedProps<typeof connect> ) {
	const { application, type } = props;
	const [ creating, setCreating ] = useState<boolean>( false );
	const [ editing, setEditing ] = useState<Variable | null>( null );
	const [ deleting, setDeleting ] = useState<Variable | null>( null );
	const [ saving, setSaving ] = useState<boolean>( false );
	const [ error, setError ] = useState<string | null>( null );

	const onCreate = async ( name: string, value: string, secret: boolean ) => {
		setError( null );
		setSaving( true );
		try {
			const res = await props.fetch( `/stack/applications/${ application.id }/variables/${ type }`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify( {
					name,
					value,
					secret,
				} ),
			} );
			if ( ! res.ok ) {
				const json = await res.json();
				throw new Error( json.message );
			}
			props.refreshData();
			setSaving( false );
			setCreating( false );
		} catch ( e ) {
			setError( e.message );
			console.error( e );
			setSaving( false );
		}
	};
	const onEdit = async ( name: string, value: string ) => {
		setError( null );
		setSaving( true );
		try {
			const res = await props.fetch( `/stack/applications/${ application.id }/variables/${ type }/${ name }`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify( {
					value,
				} ),
			} );
			if ( ! res.ok ) {
				const json = await res.json();
				throw new Error( json.message );
			}

			props.refreshData();
			setEditing( null );
			setSaving( false );
		} catch ( e ) {
			setError( e.message );
			console.error( e );
			setSaving( false );
		}
	};
	const onDelete = async ( name: string ) => {
		setError( null );
		setSaving( true );
		try {
			const res = await props.fetch( `/stack/applications/${ application.id }/variables/${ type }/${ name }`, {
				method: 'DELETE',
			} );
			if ( ! res.ok ) {
				const json = await res.json();
				throw new Error( json.message );
			}

			props.refreshData();
			setDeleting( null );
			setSaving( false );
		} catch ( e ) {
			setError( e.message );
			console.error( e );
			setSaving( false );
		}
	};

	const rootPath = `/i/${ application['altis-instance'] }/e/${ application.id }/settings/variables`;
	const tabs = [
		{
			id: 'app',
			name: 'Application',
			current: true,
			href: `${ rootPath }/app`,
		},
		{
			id: 'build',
			name: 'Builds',
			current: false,
			href: `${ rootPath }/build`,
		},
	];

	return (
		<SettingsGroup
			title="Variables & Secrets"
		>
			<div className="md:flex my-2 space-y-2 md:space-x-2 md:space-y-0">
				<div className="flex-1 space-y-2">
					<p className="text-sm text-gray-500">
						Secrets are encrypted using hardware security modules and are used for sensitive data like authentication tokens. Variables are shown as plain-text and are used for non-sensitive data like feature flags.
						{ ' ' }
						<a
							href="https://docs.altis-dxp.com/cloud/variables-secrets/"
							rel="noopener noreferrer"
						>
							Learn more <ExternalLinkIcon className="w-4 -mt-1" />
						</a>
					</p>
				</div>
				<div className="shrink-0">
					<Button
						disabled={ !! ( creating || editing ) }
						variant="primary"
						onClick={ () => setCreating( true ) }
					>
						Add variable
					</Button>
				</div>
			</div>

			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav aria-label="Tabs" className="-mb-px flex space-x-8">
						{ tabs.map( tab => (
							<NavLink
								key={ tab.name }
								aria-current={ type === tab.id ? 'page' : undefined }
								className={ [
									type === tab.id ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
									'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
								].filter( Boolean ).join( ' ' ) }
								to={ tab.href }
							>
								{ tab.name }
							</NavLink>
						) ) }
					</nav>
				</div>
			</div>

			{ type === 'build' ? (
				<p className="my-2 text-sm text-gray-500">
					These variables and secrets are only available during the build process. Any changes will apply on your next deploy.
				</p>
			) : type === 'app' ? (
				<p className="my-2 text-sm text-gray-500">
					These variables and secrets are available in both your application code and the CLI. Any changes will apply on your next deploy.
				</p>
			) : null }

			{ error && (
				<ErrorBlock
					message={ error }
				/>
			) }

			<div className="my-2">
				{ props.variables.isLoading ? (
					<div className="col-span-3 py-4 text-center text-gray-500">
						<ContainerDimensions>
							{ ( { width } ) => (
								<ContentLoader
									height={ 160 }
									speed={ 2 }
									width={ width }
								>
									{ [ 100, 80, 120 ].map( ( textWidth, idx ) => (
										<React.Fragment key={ idx }>
											{ /* Name */ }
											<rect
												height={ 20 }
												rx="2"
												ry="2"
												width={ textWidth }
												x="0"
												y={ idx * 60 }
											/>
											{ /* Value */ }
											<rect
												height={ 20 }
												rx="2"
												ry="2"
												width={ textWidth * 1.2 }
												x={ 200 }
												y={ idx * 60 }
											/>
											{ /* Last modified */ }
											<rect
												height={ 15 }
												rx="2"
												ry="2"
												width={ textWidth * 2.5 }
												x={ 200 }
												y={ idx * 60 + 25 }
											/>
											{ /* Edit button */ }
											<rect
												height={ 30 }
												rx="2"
												ry="2"
												width={ 60 }
												x={ width - 140 }
												y={ idx * 50 }
											/>
											{ /* Delete button */ }
											<rect
												height={ 30 }
												rx="2"
												ry="2"
												width={ 70 }
												x={ width - 70 }
												y={ idx * 50 }
											/>
										</React.Fragment>
									) ) }
								</ContentLoader>
							) }
						</ContainerDimensions>
					</div>
				) : props.variables.error ? (
					<div className="text-center text-red-600">Failed to load variables</div>
				) : (
					<VariableTable
						creating={ creating }
						deleting={ deleting }
						editing={ editing }
						saving={ saving }
						variables={ props.variables.data }
						onCreate={ onCreate }
						onDelete={ onDelete }
						onEdit={ onEdit }
						onSetCreating={ setCreating }
						onSetDeleting={ setDeleting }
						onSetEditing={ setEditing }
					/>
				) }
			</div>
		</SettingsGroup>
	);
}

const connect = withApiData<DataTypes, OwnProps>( props => ( {
	variables: `/stack/applications/${ props.application.id }/variables/${ props.type }`,
} ) );
export default connect( Variables );
