import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';

export interface Page {
	title: string,
	icon?: React.ElementType,
	path: string,
	route?: string,
	component: React.ElementType,
}

interface Props {
	defaultPath?: string,
	pages: Page[],
}

export default function SettingsPanel( props: Props ) {
	return (
		<main className="SettingsPanel pb-4 lg:py-6 [&_*]:box-border">
			<div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
				<aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
					<nav className="space-y-1">
						{ props.pages.map( page => (
							<NavLink
								key={ page.path }
								className={ active => `group rounded-md px-3 py-2 flex items-center text-sm transition-none ${ active ? 'bg-gray-50 !text-altis-400 hover:!bg-gray-100' : '!text-gray-700 hover:!text-gray-900 hover:!bg-gray-50' }` }
								to={ page.path }
							>
								{ page.icon && (
									<page.icon
										aria-hidden="true"
										className="flex-shrink-0 -ml-1 mr-3 h-5 w-5"
									/>
								) }
								<span className="truncate">
									{ page.title }
								</span>
							</NavLink>
						) ) }
					</nav>
				</aside>

				<div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
					<Switch>
						{ props.pages.map( page => (
							<Route
								key={ page.path }
								path={ page.route || page.path }
								render={ props => (
									<page.component
										{ ...props }
									/>
								) }
							/>
						) ) }
						{ props.defaultPath && (
							<Route>
								<Redirect
									to={ props.defaultPath }
								/>
							</Route>
						) }
					</Switch>
				</div>
			</div>
		</main>
	);
}
