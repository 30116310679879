import React, { useState, useEffect } from 'react';

import { ConnectedProps, withApiData } from '../lib/with-api-data';

import LineChart from './LineChart';

const INTERVAL_S = 45;

type Data = {
	value: number,
}

interface ComponentProps {
	data: Data[],
	domain: any,
	fadeOut: any,
	height: number,
	timeMarker?: any,
	unit?: string,
	width?: number,
	onHoverTimeline?(): any,
}

type DataProps = {
	metrics: Data[],
}

interface OwnProps {
	component?: React.ComponentType<ComponentProps>,
	endpoint: string,
	nullIsZero: boolean,
}

type Props = OwnProps & ConnectedProps<typeof connect> & Omit<ComponentProps, 'data'>;

function NewLiveLineChart( props: Props ) {
	const { refreshData } = props;
	const [ data, setData ] = useState<Data[] | undefined>();

	useEffect( () => {
		const interval = window.setInterval( () => {
			refreshData();
		}, 1000 * INTERVAL_S );

		return () => {
			clearInterval( interval );
		};
	}, [ refreshData ] );

	// Store data and cache.
	useEffect( () => {
		if ( ! props.metrics.isLoading && ! props.metrics.error && props.metrics.data ) {
			setData( props.metrics.data );
		}
	}, [ props.metrics, setData ] );

	const Comp = props.component || LineChart;
	return (
		<div className="LiveLineChart">
			{ data && (
				<Comp
					data={ data }
					domain={ props.domain }
					fadeOut={ props.fadeOut }
					height={ props.height }
					timeMarker={ props.timeMarker }
					unit={ props.unit }
					width={ props.width }
					onHoverTimeline={ props.onHoverTimeline }
				/>
			) }
		</div>
	);
}

const connect = withApiData<DataProps, OwnProps>( ( props: OwnProps ) => ( {
	metrics: props.endpoint,
} ) );

export default connect( NewLiveLineChart );
