import PropTypes from 'prop-types';
import React from 'react';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';

import ActivityListItem from '../ActivityListItem';
import OutlineButton from '../OutlineButton';
import SolidButton from '../SolidButton';

import Details from './Details';

import './index.css';

const formatDescription = item => {
	if ( ! item.commit || ! item.commit.commit ) {
		if ( item.status === 'in_progress' ) {
			return 'Build in progress…';
		}

		return 'Unknown commit';
	}

	const commit = item.commit.commit;
	const shortRev = commit.oid.substr( 0, 7 );
	const shortDesc = commit.message.split( '\n' )[0];
	return `${ shortRev } | ${ shortDesc }`;
};

export default class UnifiedDeployListItem extends React.Component {
	state = {
		isShowingDetails: false,
	};

	constructor( props ) {
		super( props );
		this.state.isShowingDetails = props.defaultOpen;
	}

	onToggleBuildDetails = () => {
		this.setState( {
			isShowingDetails: ! this.state.isShowingDetails,
		} );
	};

	render() {
		const { application, build, canDeploy, canRedeploy, current, currentBuild, deploys, deploying, isDeploying, pinned, onDeploy } = this.props;

		const currentDeploy = current && deploys.find( deploy => deploy.id === current.id );
		const isNewerThanCurrent = ! currentBuild || new Date( build.date ) > new Date( currentBuild.date );
		const buildInProgress = build.status === 'in_progress';
		const buildFailed = build.status === 'failed';

		const classes = [
			'UnifiedDeployListItem',
			( this.props.deploying || this.state.isShowingDetails ) ? 'UnifiedDeployListItem--expanded' : null,
			currentDeploy && 'UnifiedDeployListItem--current',
			pinned && 'UnifiedDeployListItem--pinned',
		];

		let description = formatDescription( build );
		if ( deploys && deploys.length > 1 ) {
			description = `${ description } | ${ deploys.length } deploys`;
		}
		if ( this.props.renderBuildDescription ) {
			description = this.props.renderBuildDescription( description, build );
		}

		const commit = build.commit && build.commit.commit;
		let formattedUser = commit && {
			name: commit.author.name,
			avatar_urls: {
				'182': commit.author.avatarUrl,
			},
		};
		if ( build.user ) {
			formattedUser = build.user;
		}

		const expanded = this.props.deploying || this.state.isShowingDetails;

		return (
			<div
				className={ classes.filter( Boolean ).join( ' ' ) }
			>
				<div className="UnifiedDeployListItem__summary">
					<button
						className="UnifiedDeployListItem__expando"
						type="button"
						onClick={ this.onToggleBuildDetails }
					>
						{ expanded ? <ChevronDownIcon /> : <ChevronRightIcon /> }
					</button>

					<ActivityListItem
						date={ build.date }
						description={ description }
						user={ formattedUser }
					/>

					{ currentDeploy && (
						<strong className="mr-4">Current</strong>
					) }

					{ deploying ? (
						<SolidButton disabled name="Deploying…" onClick={ () => {} } />
					) : buildInProgress ? (
						<SolidButton disabled name="Building..." onClick={ () => {} } />
					) : buildFailed ? (
						<SolidButton disabled name="Build Failed" onClick={ () => {} } />
					) : canDeploy && (
						currentDeploy ? (
							canRedeploy && (
								<OutlineButton
									disabled={ isDeploying }
									name="Redeploy"
									onClick={ onDeploy }
								/>
							)
						) : isNewerThanCurrent ? (
							<SolidButton
								disabled={ isDeploying }
								name="Deploy"
								onClick={ onDeploy }
							/>
						) : (
							<OutlineButton
								disabled={ isDeploying }
								name="Rollback"
								onClick={ onDeploy }
							/>
						)
					) }
				</div>

				{ expanded && (
					<Details
						application={ application }
						build={ build }
						current={ current }
						deploys={ deploys }
						onBuildCompleted={ this.props.onBuildCompleted }
					/>
				) }
			</div>
		);
	}
}

export const deployShape = PropTypes.shape( {
	defaultOpen: PropTypes.bool,
	status: PropTypes.string.isRequired,
	rev: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	deployTask: PropTypes.shape( {
		messages: PropTypes.arrayOf(
			PropTypes.shape( {
				message: PropTypes.string.isRequired,
			} )
		).isRequired,
		phases: PropTypes.arrayOf(
			PropTypes.shape( {
				phaseType: PropTypes.string.isRequired,
				phaseStatus: PropTypes.string.isRequired,
			} )
		),
	} ),
	renderBuildDescription: PropTypes.func,
} );

UnifiedDeployListItem.propTypes = {
	application: PropTypes.object.isRequired,
	build: PropTypes.object.isRequired,
	canDeploy: PropTypes.bool.isRequired,
	current: deployShape,
	currentBuild: PropTypes.object,
	defaultOpen: false,
	deploys: PropTypes.arrayOf( deployShape ),
	pinned: PropTypes.bool,
	onDeploy: PropTypes.func.isRequired,
	onBuildCompleted: PropTypes.func.isRequired,
};
