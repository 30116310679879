import React, { useState } from 'react';

export type PaginationProps = {
	page: number,
	setPage( page: number ): void,
}

export default function withPagination<T extends PaginationProps>(
	Component: React.ComponentType<T>
) {
	return ( props: Omit<T, keyof PaginationProps> ) => {
		const [ page, setPage ] = useState<number>( 1 );

		const fullProps = {
			...props,
			page,
			setPage,
		} as T;
		return (
			<Component
				{ ...fullProps }
			/>
		);
	};
}
