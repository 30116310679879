import React from 'react';

import { withApiData } from '../../lib/with-api-data';
import { ApiResponse } from '../../types/api';

import { BasicTableWithWarning } from './BasicTable';
import { BasicLogEntry } from './util';

type DataProps = {
	logs: BasicLogEntry[],
};
type MapReturn = {
	logs: ApiResponse<BasicLogEntry[]>,
}

export default function connectBasicTable<T>( mapPropsToEndpoint: ( props: T ) => string ) {
	const decorator = withApiData<DataProps, T>(
		props => ( { logs: mapPropsToEndpoint( props ) } )
	);

	return decorator( ( props: MapReturn ) => (
		<BasicTableWithWarning
			data={ props.logs }
		/>
	) );
}
