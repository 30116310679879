import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createDeploy, fetchTaskStream } from '../../actions';
import { collectBuilds } from '../../lib/builds';
import { withApiData } from '../../lib/with-api-data';
import ApplicationBuildCacheButton from '../ApplicationBuildCacheButton';
import ApplicationBuildRebuildButton from '../ApplicationBuildRebuildButton';
import ApplicationDeployLockActions from '../ApplicationDeployLockActions';
import DeployList from '../DeployList';
import ErrorBlock from '../ErrorBlock';
import OutlineButton from '../OutlineButton';
import PageTitle from '../PageTitle';
import UnifiedDeployList from '../UnifiedDeployList';

import Config from './Config';
import CreateDeploy from './CreateDeploy';

import './index.css';

class ApplicationDeploys extends Component {
	state = {
		selectedBuild: null,
		isReleasingDeployLocks: false,
		releaseDeployLocksError: null,
	};

	onDeploy = ( build = null ) => {
		return this.props.dispatch(
			createDeploy( this.props.application, build, this.props )
		).then( () => this.props.refreshData() );
	};

	onBuildCompleted = () => {
		this.props.refreshData();
	};

	onReleaseDeployLocks() {
		this.setState( {
			isReleasingDeployLocks: true,
			releaseDeployLocksError: null,
		} );
		this.props
			.fetch( `/stack/applications/${this.props.application.id}/deploys/locks`, {
				method: 'DELETE',
			} )
			.then( () => this.setState( { isReleasingDeployLocks: false } ) )
			.catch( releaseDeployLocksError =>
				this.setState( {
					releaseDeployLocksError,
					isReleasingDeployLocks: false,
				} )
			);
	}

	onRefresh = () => {
		this.props.refreshData();
	};

	render() {
		// Merge in any deploy tasks into the deploys list
		let deploys = this.props.deploys.data ? this.props.deploys.data.slice() : [];
		if ( this.props.application && deploys ) {
			deploys = deploys.map( deploy => ( {
				...deploy,
				deployTask: this.props.deployTasks[`${this.props.application.id}/deploys:${deploy.id}`],
			} ) );
		}

		// Current (i.e. active) deploy is just the latest successful one.
		const currentDeploy = deploys ? deploys[0] : null;

		// Work out if there's a deploy in progress.
		const isDeploying = this.props.deployStatus && this.props.deployStatus.creating;
		const deployingBuild = isDeploying && this.props.deployStatus.build;

		const builds = collectBuilds(
			this.props.builds.data || null,
			this.props.deploys.data || null
		);

		const isEcs = this.props.application.architecture === 'terraform-ecs-application';

		return (
			<div className="ApplicationDeploys">
				<PageTitle title="Release" />

				<Config
					data={ this.props.application['git-deployment'] }
				>
					{ this.props.canClearLocks && (
						<ApplicationDeployLockActions
							isReleasingDeployLocks={ this.state.isReleasingDeployLocks }
							releaseDeployLocksError={ this.state.releaseDeployLocksError }
							onReleaseDeployLocks={ () => this.onReleaseDeployLocks() }
						/>
					) }

					<ApplicationBuildCacheButton
						application={ this.props.application }
						showClearBuildCache={ this.props.application.can.develop }
					/>

					{ isEcs && (
						<ApplicationBuildRebuildButton
							application={ this.props.application }
						/>
					) }
				</Config>
				<header className="ApplicationDeploys__header">
					<h2>Latest Builds</h2>
					<OutlineButton
						name="Refresh"
						onClick={ this.onRefresh }
					/>
				</header>

				{ ! isEcs && (
					<CreateDeploy
						application={ this.props.application }
						currentDeploy={ currentDeploy }
						isDeploying={ isDeploying }
						onDeploy={ () => this.onDeploy() }
					/>
				) }

				{ this.props.deploys.error && ! this.props.deploys.isLoading ? (
					<ErrorBlock message={ this.props.deploys.error.message } />
				) : (
					isEcs ? (
						<UnifiedDeployList
							application={ this.props.application }
							canDeploy={ this.props.canDeploy }
							canRedeploy={ this.props.canDeploy }
							current={ currentDeploy ? currentDeploy : null }
							deploying={ deployingBuild }
							inProgress={ this.props.buildInProgress || null }
							isDeploying={ isDeploying }
							isLoading={ this.props.deploys.isLoading }
							items={ builds || [] }
							onBuildCompleted={ this.onBuildCompleted }
							onDeploy={ this.onDeploy }
						/>
					) : (
						<DeployList
							current={ currentDeploy ? currentDeploy : null }
							isLoading={ this.props.deploys.isLoading }
							items={ deploys }
							onToggleDeployDetails={ deploy => this.props.onToggleDeployDetails( this.props.application, deploy ) }
						/>
					)
				) }
			</div>
		);
	}
}

const ApplicationDeploysWithApiData = withApiData( props => {
	return {
		builds: `/stack/applications/${props.match.params.application}/builds`,
		deploys: `/stack/applications/${props.match.params.application}/deploys`,
	};
} )( ApplicationDeploys );

const mapStateToProps = ( state, props ) => ( {
	isDeploying:
		state.applicationDeploys[props.match.params.application] !== undefined
			? state.applicationDeploys[props.match.params.application].creating
			: false,
	deployStatus: state.applicationDeploys[ props.match.params.application ],
	deployTasks: state.tasks.byId,
	application: state.applications[props.match.params.application],
	buildInProgress: state.applicationBuilds[ props.match.params.application ],
	canClearLocks: state.currentUser.capabilities.edit_applications,
} );

const mapDispatchToProps = dispatch => {
	return {
		onToggleDeployDetails: function ( application, deploy ) {
			if ( ! deploy.deployTask ) {
				dispatch( fetchTaskStream( `${application.id}/deploys:${deploy.id}`, application ) );
			}
			dispatch( {
				type: 'TOGGLE_DEPLOY_DETAILS',
				payload: {
					id: deploy.id,
				},
			} );
		},
		dispatch( ...args ) {
			return dispatch( ...args );
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)( ApplicationDeploysWithApiData );
