import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';

import { ApiResponse } from '../types/api';

type Fetch = Window['fetch'];
type CachedResponse<T = any> = {
	data: T,
	response: Response,
}
type CachedValue<T = any> = CachedResponse<T> | Error | 'pending';
interface CacheMap<T = any> {
	[ k: string ]: CachedValue<T>,
}

type Callback<T> = ( data: T ) => void;
type Subscriptions<T = any> = {
	[ k: string ]: Callback<T>[],
};

const caches: { [ k: string ]: ApiCache } = {};

class ApiCache {
	cache: CacheMap;
	eventSubscribers: Subscriptions;
	fetch: Fetch;

	constructor( fetch: Fetch, initialData: CacheMap = {} ) {
		this.fetch = fetch;
		this.cache = { ...initialData };
		this.eventSubscribers = {};
	}
	get( url: string, params: RequestInit = {} ) {

		const promise = this.fetch( url, params )
			.then( r => this.handleResponse( r ) )
			.then( response => {
				this.setCache( url, response );
				this.trigger( url, response );
			} )
			.catch( error => {
				this.setCache( url, error );
				this.trigger( url, error );
			} );

		this.setCache( url, 'pending' );

		return promise;
	}
	getCache( url: string ) {
		return this.cache[ url ];
	}
	setCache<T>( url: string, response: CachedValue<T> ) {
		this.cache[ url ] = response;
	}
	handleResponse( response: Response ) {
		return response.text().then( responseText => {
			let json;
			try {
				json = JSON.parse( responseText );
			} catch ( e ) {
				throw new Error( responseText );
			}

			if ( response.status > 299 ) {
				throw new Error( json.message );
			}
			return {
				data: json,
				response,
			};
		} );
	}
	on<T>( url: string, callback: Callback<CachedValue<T>> ) {
		this.eventSubscribers[ url ] = this.eventSubscribers[ url ] || [];
		this.eventSubscribers[ url ].push( callback );

		if ( this.getCache( url ) === 'pending' ) {
			return;
		}
		if ( this.getCache( url ) ) {
			return callback( this.getCache( url ) );
		}
		this.get( url );
	}
	trigger<T>( url: string, response: CachedResponse<T> ) {
		if ( ! this.eventSubscribers[url] ) {
			console.log( 'no subscribers found for url', url );
		}
		this.eventSubscribers[url].map( f => f( response ) );
	}
	removeCache( url: string ) {
		delete this.cache[ url ];
	}
}

interface ProviderProps {
	cacheKey?: string,
	children: React.ReactNode,
	fetch: Fetch,
	initialData?: CacheMap,
}

interface Context {
	api: Fetch,
	apiCache: ApiCache,
}

export class Provider extends Component<ProviderProps> {
	apiCache: ApiCache;

	static childContextTypes = {
		api: () => null,
		apiCache: () => null,
	};

	constructor( props: ProviderProps ) {
		super( props );
		if ( props.cacheKey ) {
			if ( ! caches[ props.cacheKey ] ) {
				caches[ props.cacheKey ] = new ApiCache( props.fetch, props.initialData );
			}
			this.apiCache = caches[ props.cacheKey ];
		} else {
			this.apiCache = new ApiCache( props.fetch, props.initialData );
		}
	}
	getChildContext() : Context {
		return {
			api: this.props.fetch,
			apiCache: this.apiCache,
		};
	}
	render() {
		return this.props.children;
	}
}

type OtherInjectedProps = {
	fetch( url: string, params: RequestInit ): Promise<Response>,
	post( url: string, data: any ): Promise<Response>,
	// ref={ref => this.wrapperRef = ref}
	refreshData(): void,
	invalidateData(): void,
	invalidateDataForUrl( url: string ): void,
};

type FlatMap = {
	[ k: string ]: string | undefined,
};
type MapPropsToData<TStateProps = unknown, TOwnProps = unknown> =
	( props: TOwnProps ) => Record<keyof TStateProps, string | undefined>;

type InferableComponentEnhancerWithProps<TInjectedProps, TOwnProps> =
    <P extends TInjectedProps & TOwnProps>( component: React.ComponentType<P> ) =>
        React.ComponentType<Omit<P, keyof TInjectedProps> & TOwnProps>;

export type ConnectedProps<TConnector> =
	TConnector extends InferableComponentEnhancerWithProps<infer TInjectedProps, infer _TOwnProps>
		? TInjectedProps & OtherInjectedProps
		: never;

export function withApiData<
	TStateProps extends {},
	TOwnProps = {},
>( mapPropsToData: MapPropsToData<TStateProps, TOwnProps> ) {
	type TDataProps = {
		[k in keyof TStateProps]: ApiResponse<TStateProps[k]>;
	};
	type TMergedProps = TDataProps & OtherInjectedProps & TOwnProps;

	const component = ( ( WrappedComponent: React.ComponentType<TMergedProps> ) => {
		// Derive display name from original component
		const { displayName = WrappedComponent.name || 'Component' } = WrappedComponent;

		return class APIDataComponent extends Component<TOwnProps, TDataProps> {
			context!: Context;
			unmounted: boolean;
			wrapperRef?: React.Ref<typeof WrappedComponent>;

			static contextTypes = {
				api: () => null,
				apiCache: () => null,
			};
			static displayName = `apiData(${ displayName })`;

			constructor( props: TOwnProps ) {
				super( props );

				this.unmounted = true;
				const dataMap = mapPropsToData( this.props );
				const keys = Object.keys( dataMap );
				const dataProps: { [ k: string ]: ApiResponse<unknown> } = {};
				keys.forEach( key => {
					dataProps[ key ] = {
						isLoading: true,
						error: null,
						data: null,
						response: null,
					};
				} );
				this.state = dataProps as TDataProps;
			}

			componentDidMount() {
				this.unmounted = false;
				this.updateProps( this.props );
			}

			componentWillUnmount() {
				this.unmounted = true;
			}

			componentWillReceiveProps( nextProps: TOwnProps ) {
				const oldDataMap = mapPropsToData( this.props );
				const newDataMap = mapPropsToData( nextProps );
				if ( isEqual( oldDataMap, newDataMap ) ) {
					return;
				}
				// When the `mapPropsToData` function returns a different
				// result, reset all the data to empty and loading.
				const keys = Object.keys( newDataMap );
				const dataProps: { [ k: string ]: ApiResponse<unknown> } = {};
				keys.forEach( key => {
					dataProps[ key ] = {
						// @ts-ignore We null this to avoid errors.
						url: null,
						isLoading: true,
						error: null,
						data: null,
						response: null,
					};
				} );
				this.setState( dataProps as TDataProps, () => this.updateProps( nextProps ) );
			}

			updateProps = ( props: TOwnProps ) => {
				const dataMap: FlatMap = mapPropsToData( props );

				Object.entries( dataMap as FlatMap ).forEach( ( [ key, endpoint ] ) => {
					if ( ! endpoint ) {
						return;
					}
					// @ts-ignore Extra `url` property
					this.setState( {
						[ key ]: {
							isLoading: true,
							error: null,
							data: null,
							response: null,
							url: endpoint,
						},
					} as TDataProps );
					this.context.apiCache.on( endpoint, info => {
						// todo: Check behaviour?
						if ( info === 'pending' ) {
							return;
						}

						// let { data, response } = info;
						let error: Error | null = null;
						if ( this.unmounted ) {
							return;
						}

						let data: any, response: Response | undefined;
						if ( info instanceof Error ) {
							error = info;
							data = null;
						} else {
							data = info.data;
							response = info.response;
						}
						this.setState( state => {
							// Check for race conditions
							// @ts-ignore Extra `url` property
							if ( state[ key ].url !== endpoint ) {
								return {};
							}

							const prop = {
								error,
								isLoading: false,
								data,
								response,
							};
							return { [ key ]: prop } as TDataProps;
						} );
					} );
				} );
			};

			onFetch = ( ...args: Parameters<typeof this.context.api> ) => {
				return this.context.api( ...args );
			};

			onRefreshData = () => {
				this.onInvalidateData();
			};

			onInvalidateData = () => {
				const dataMap: FlatMap = mapPropsToData( this.props );
				Object.values( dataMap ).forEach( endpoint => {
					if ( ! endpoint ) {
						return;
					}
					this.context.apiCache.removeCache( endpoint );
				} );
				this.updateProps( this.props );
			};

			onInvalidateDataForUrl = ( url: string ) => {
				this.context.apiCache.removeCache( url );
				this.updateProps( this.props );
				this.context.apiCache.get( url );
			};

			onPost( url: string, data: any ) {
				return this.onFetch( url, {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify( data ),
					method: 'POST',
				} ).then( response => {
					return response.text().then( responseText => {
						let json;
						try {
							json = JSON.parse( responseText );
						} catch ( e ) {
							throw new Error( responseText );
						}
						return json;
					} );
				} );
			}

			getWrappedInstance() {
				return this.wrapperRef;
			}

			render() {
				return (
					<WrappedComponent
						ref={ ( ref: React.Ref<typeof WrappedComponent> ) => this.wrapperRef = ref }
						{ ...( this.props as TOwnProps ) }
						{ ...( this.state as TDataProps ) }
						fetch={ ( ...args ) => this.onFetch( ...args ) }
						invalidateData={ () => this.onInvalidateData() }
						invalidateDataForUrl={ ( ...args ) => this.onInvalidateDataForUrl( ...args ) }
						post={ ( ...args ) => this.onPost( ...args ) }
						refreshData={ ( ...args ) => this.onRefreshData( ...args ) }
					/>
				);
			}
		};
	} );

	return component as InferableComponentEnhancerWithProps<
		TDataProps & OtherInjectedProps,
		TOwnProps & {
			ref?: React.Ref<Parameters<typeof component>[0]>,
		}
	>;
}

interface ClassProps<TStateProps, TDataProps> {
	data: Record<keyof TStateProps, string>,
	render: ( props: TDataProps ) => React.ReactNode,
}

export class WithApiData<
	TStateProps,
	TDataProps = {
		[k in keyof TStateProps]: ApiResponse<TStateProps[k]>;
	},
> extends Component<ClassProps<TStateProps, TDataProps>, TDataProps> {
	context!: Context;
	state: TDataProps;
	unmounted: boolean;

	static contextTypes = {
		api: () => null,
		apiCache: () => null,
	};

	constructor( props: ClassProps<TStateProps, TDataProps> ) {
		super( props );

		this.unmounted = true;
		const dataMap = props.data;
		const keys = Object.keys( dataMap );

		const dataProps: Partial<TDataProps> = {};
		keys.forEach( key => {
			const validKey = key as keyof TDataProps;
			dataProps[ validKey ] = {
				isLoading: true,
				error: null,
				data: null,
				response: null,
			} as unknown as TDataProps[ typeof validKey ];
		} );
		this.state = dataProps as TDataProps;
	}

	componentDidMount() {
		this.unmounted = false;
		this.updateProps( this.props );
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	componentWillReceiveProps( nextProps: ClassProps<TStateProps, TDataProps> ) {
		const oldDataMap = this.props.data;
		const newDataMap = nextProps.data;
		if ( isEqual( oldDataMap, newDataMap ) ) {
			return;
		}
		// When the `mapPropsToData` function returns a different
		// result, reset all the data to empty and loading.
		const keys = Object.keys( newDataMap );
		const dataProps: Partial<TDataProps> = {};
		keys.forEach( key => {
			const validKey = key as keyof TDataProps;
			dataProps[ validKey ] = {
				isLoading: true,
				error: null,
				data: null,
				response: null,
			} as unknown as TDataProps[ typeof validKey ];
		} );

		this.setState( dataProps as TDataProps, () => this.updateProps( nextProps ) );
	}

	invalidateData() {
		const dataMap: FlatMap = this.props.data;
		Object.values( dataMap ).forEach( endpoint => {
			if ( ! endpoint ) {
				return;
			}

			this.context.apiCache.removeCache( endpoint );
		} );
		this.updateProps( this.props );
	}

	updateProps( props: ClassProps<TStateProps, TDataProps> ) {
		const dataMap: FlatMap = props.data;

		Object.entries( dataMap ).forEach( ( [ key, endpoint ] ) => {
			if ( ! endpoint ) {
				return;
			}
			const validKey = key as keyof TDataProps;
			this.setState( {
				[ validKey ]: {
					isLoading: true,
					error: null,
					data: null,
					response: null,
					url: endpoint,
				},
			} as unknown as TDataProps );
			this.context.apiCache.on( endpoint, info => {
				// todo: Check behaviour?
				if ( info === 'pending' ) {
					return;
				}

				let data: any, response: Response | undefined;
				let error: Error | null = null;
				if ( this.unmounted ) {
					return data;
				}

				if ( info instanceof Error ) {
					error = info;
					data = null;
				} else {
					data = info.data;
					response = info.response;
				}
				this.setState( state => {
					// Check for race conditions
					// @ts-ignore Extra `url` property
					if ( state[ key as keyof typeof state ].url !== endpoint ) {
						return {};
					}

					const prop = {
						error,
						isLoading: false,
						data,
						response,
					};
					return { [ key ]: prop } as TStateProps;
				} );
			} );
		} );
	}

	render() {
		return this.props.render( { ...this.state } );
	}
}
