import qs from 'qs';
import React from 'react';

import { ConnectedProps, withApiData } from '../../lib/with-api-data';
import ErrorBlock from '../ErrorBlock';

import FilterForm from './FilterForm';
import SummaryList from './SummaryList';
import TraceStats from './TraceStats';
import { queryForTimeFilter, TimeFilter } from './util';

interface Props {
	application: string,
	filter: string,
	time: TimeFilter,
	onSelectFilter( filter: any ): void,
	onSelectTrace( traceId: string ): void,
	onUpdateFilter( filter: string ): void,
}

interface DataTypes {
	summaries: any[],
}

type AllProps = Props & ConnectedProps<typeof connect>;

export class TraceList extends React.Component<AllProps> {
	// Allow refreshing data via refs.
	onRefresh() {
		this.props.refreshData();
	}

	render() {
		const data = ( this.props.summaries.isLoading ? null : this.props.summaries.data ) || [];
		return (
			<div className="XRayOverview-TraceList">
				<h2>Traces</h2>

				<div className="XRayFilterForm">
					<FilterForm
						initialFilter={ this.props.filter }
						onRefresh={ this.props.refreshData }
						onSubmit={ this.props.onUpdateFilter }
					/>
				</div>

				{ this.props.summaries.error ? (
					<ErrorBlock message={ this.props.summaries.error.message } />
				) : (
					<>
						<TraceStats
							isLoading={ this.props.summaries.isLoading }
							traceSummaries={ data }
							onSelectFilter={ this.props.onSelectFilter }
						/>
						<SummaryList
							isLoading={ this.props.summaries.isLoading }
							traceSummaries={ data }
							onSelectTrace={ this.props.onSelectTrace }
						/>
					</>
				) }
			</div>
		);
	}
}

const connect = withApiData<DataTypes, Props>( ( props: Props ) => {
	const base = `/stack/applications/${ props.application }/xray/traces/summaries`;

	const params: { [ key : string ]: string | number } = {
		per_page: 500,
		filter: props.filter || '',
		...queryForTimeFilter( props.time ),
	};

	return {
		summaries: base + '?' + qs.stringify( params ),
	};
} );

export default connect( TraceList );
