import React, { Component } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import ContentLoader from 'react-content-loader';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ConnectedProps, withApiData } from '../../lib/with-api-data';
import { DatabaseServerResponse } from '../../types/rest-api';
import ErrorBlock from '../ErrorBlock';
import FormattedDateTime from '../FormattedDateTime';
import FormattedRelativeTime from '../FormattedRelativeTime';
import OutlineButton from '../OutlineButton';
import PageTitle from '../PageTitle';

import './index.css';

const SnapshotItemLoading = () => (
	<ContainerDimensions>
		{ ( { width } ) => (
			<ContentLoader
				className="ApplicationData__snapshot-loading"
				height={ 86 }
				speed={ 2 }
				width={ width }
			>
				<rect height="18" rx="2" ry="2" width={ 600 } x="0" y="13" />
				<rect height="18" rx="2" ry="2" width={ 300 } x="0" y="40" />
			</ContentLoader>
		) }
	</ContainerDimensions>
);

type SnapshotProps = {
	automated: boolean,
	date: string,
	id: string,
	size: number,
};

const SnapshotItem = ( props: SnapshotProps ) => {
	const date = new Date( props.date );
	return (
		<div className="ApplicationData__snapshot">
			<div className="ApplicationData__snapshot-description">
				<p className="ApplicationData__snapshot-id"><code>{ props.id }</code></p>
				<p>
					<FormattedDateTime
						format="long"
						value={ date }
					/>
					{ ' • ' }
					<span className="ApplicationData__snapshot-size">
						{ props.size < 1024 ? (
							<span>&lt;1 GB</span>
						) : (
							<span>{ Math.round( props.size / 1024 / 1014 / 1024 ) } GB</span>
						) }
					</span>
					{ ' • ' }
					<span className="ApplicationData__snapshot-automated">
						{ props.automated ? 'Automated backup' : 'Manual backup' }
					</span>
				</p>
			</div>
		</div>
	);
};

type Props = RouteComponentProps<{
	application: string,
}>;

type DataProps = {
	server: DatabaseServerResponse,
	snapshots: any[],
}

const UPDATE_INTERVAL = 60 * 1000;

class ApplicationData extends Component<Props & ConnectedProps<typeof connect>> {
	interval?: ReturnType<typeof setInterval>;

	componentDidMount() {
		this.interval = setInterval( this.onUpdate, UPDATE_INTERVAL );
	}

	componentWillUnmount() {
		if ( this.interval ) {
			clearInterval( this.interval );
		}
	}

	onUpdate = () => {
		this.props.invalidateDataForUrl( `/stack/applications/${ this.props.match.params.application }/database-server` );
	};

	render() {
		if ( ! this.props.server ) {
			return null;
		}

		if ( this.props.server.error ) {
			return (
				<ErrorBlock
					message={ this.props.server.error.message }
				/>
			);
		}

		if ( ! this.props.server.isLoading && ! this.props.server.data.details ) {
			return (
				<p>Coming soon. <span aria-label="Ssssh…" role="img">🤫</span></p>
			);
		}

		const latestPIT = this.props.server.isLoading ? null : this.props.server.data?.details.backups.point_in_time.latest;
		const latestPITDate = latestPIT ? new Date( latestPIT ) : null;

		return (
			<div className="ApplicationData">
				<PageTitle title="Backups" />

				<div className="ApplicationData__info">
					<p>
						Altis automatically backs up your database every 5
						minutes for point-in-time disaster recovery.
						Daily snapshots are taken to allow restoring to
						previous data.
					</p>

					{ ! this.props.server.isLoading && this.props.server.data && (
						<p>
							Daily snapshots are retained for
							{ ` ${ this.props.server.data.details.backups.retention } ` }
							days by default.
							(Backups for Enterprise customers may have longer
							retention; consult your account manager.)
						</p>
					) }

					<p>
						These snapshots are displayed below for{ ' ' }
						<strong>informational purposes only</strong>, and can
						only be restored by the Altis Cloud team.{ ' ' }
						<Link to="/support/new">Contact support to
						request disaster recovery</Link>.
					</p>

					<p>
						To take a copy of your database for local usage, create
						an <Link to="./import-export">SQL export</Link>.
					</p>
				</div>

				<header className="ApplicationData__header">
					<h2>Latest Snapshots</h2>
					<OutlineButton
						name="Refresh"
						onClick={ () => this.props.invalidateData() }
					/>
				</header>

				{ this.props.server.isLoading && (
					<SnapshotItemLoading />
				) }

				{ latestPITDate && (
					<div className="ApplicationData__snapshot ApplicationData__snapshot--pit">
						<div className="ApplicationData__snapshot-description">
							<p className="ApplicationData__snapshot-id">Latest point-in-time</p>
							<p>
								<FormattedDateTime
									format="long"
									value={ latestPITDate }
								/>
								{ ' (' }
								<FormattedRelativeTime
									value={ latestPITDate }
								/>
								{ ')' }
							</p>
						</div>
					</div>
				) }

				{ this.props.snapshots.isLoading ? (
					<>
						<SnapshotItemLoading />
						<SnapshotItemLoading />
					</>
				) : this.props.snapshots.data && (
					this.props.snapshots.data.map( snapshot => (
						<SnapshotItem
							key={ snapshot.id }
							{ ...snapshot }
						/>
					) )
				) }
			</div>
		);
	}
}

const connect = withApiData<DataProps, Props>( props => ( {
	server: `/stack/applications/${ props.match.params.application }/database-server`,
	snapshots: `/stack/applications/${ props.match.params.application }/database-server/snapshots`,
} ) );

export default connect( ApplicationData );
