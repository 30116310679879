import React, { useState } from 'react';
import CheckIcon from 'react-feather/dist/icons/check';
import OctagonIcon from 'react-feather/dist/icons/x-octagon';
import { Link } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import ApplicationLogsWarning from '../ApplicationLogsWarning';
import Modal from '../Modal';
import NoItemsFoundBlock from '../NoItemsFoundBlock';
import PillButton from '../PillButton';
import SortableTable, { Columns } from '../SortableTable';

import Header, { TypeFilter } from './Header';
import Level from './Level';
import Timestamp from './Timestamp';
import { Filter, TimeProps } from './util';

import './Email.css';

type SESString = {
	Data: string,
	Charset: string,
};
type SESMessage = {
	Source?: string,
	Destination?: {
		BccAddresses?: string[],
		CcAddresses?: string[],
		ToAddresses?: string[],
	},
	Message?: {
		Subject: SESString,
		Body: {
			Html?: SESString,
			Text?: SESString,
		},
	},
	RawMessage?: SESString,
}
interface EmailSuccessEntry extends SESMessage {
	date: string,
	id: string,
}
interface EmailEntry {
	date: string,
	id: string,
	error?: {
		class: string,
		message: string,
	},
	message: SESMessage,
}
type RawEmailEntry = EmailSuccessEntry | EmailEntry;

const normalizeEntry = ( entry: RawEmailEntry ) : EmailEntry => {
	if ( ( entry as EmailSuccessEntry ).Source || ( entry as EmailSuccessEntry ).RawMessage ) {
		const { date, id, ...message } = entry as EmailSuccessEntry;
		return {
			date,
			id,
			message,
		};
	}
	return entry as EmailEntry;
};

type DetailsProps = {
	item: EmailEntry,
}

const EmailDetails = ( props: DetailsProps ) => {
	const { date, error, id, message } = props.item;
	if ( message.RawMessage ) {
		return (
			<pre>{ message.RawMessage.Data }</pre>
		);
	}

	// Function to parse and simplify the error message for SES AccessDenied exceptions
	const parseErrorMessage = ( errorMessage: string ) => {
		if ( error?.class === 'Aws\\Ses\\Exception\\SesException' ) {
			const match = errorMessage.match( /arn:aws:ses:[^:]+:[^:]+:identity\/(.+?)'/ );
			if ( match && match[1] ) {
				const senderIdentity = match[1];
				const isEmail = senderIdentity.includes( '@' );
				const type = isEmail ? 'Unauthorized sender email address' : 'Unauthorized sender domain';
				const brief = (
					<>
						Email sending failed because the sender { isEmail ? 'email address' : 'domain' } <strong> { senderIdentity } </strong> is not configured for email sending on your environment.
					</>
				);
				const action = (
					<>
						Verify that the sender { isEmail ? 'email address' : 'domain' } <strong> { senderIdentity } </strong> is correct and configured for email sending on this environment. Consult the <a href='https://docs.altis-dxp.com/cloud/email-delivery/'>email delivery documentation</a> for more information. Contact <Link to="/support/new">Altis support</Link> for further assistance.
					</>
				);
				return {
					type,
					brief,
					action,
				};
			}
		}
		return null;
	};

	// Check if there is an error and parse it
	const simplifiedError = error ? parseErrorMessage( error.message ) : null;

	return (
		<div className="ApplicationLogs-Email__detail">
			<header>
				<table className="ApplicationLogs-Email__detail-headers">
					<tbody>
						<tr>
							<th scope="row">Message ID</th>
							<td>{ id }</td>
						</tr>

						<tr>
							<th scope="row">Timestamp</th>
							<td>{ date }</td>
						</tr>

						<tr>
							<th scope="row">From:</th>
							<td>{ message.Source || '' }</td>
						</tr>

						{ message.Destination?.ToAddresses && (
							<tr>
								<th scope="row">To:</th>
								<td>{ message.Destination?.ToAddresses?.join( ', ' ) }</td>
							</tr>
						) }

						{ message.Destination?.CcAddresses && (
							<tr>
								<th scope="row">CC:</th>
								<td>{ message.Destination?.CcAddresses?.join( ', ' ) }</td>
							</tr>
						) }

						{ message.Destination?.BccAddresses && (
							<tr>
								<th scope="row">BCC:</th>
								<td>{ message.Destination?.BccAddresses?.join( ', ' ) }</td>
							</tr>
						) }
						<tr>
							<th scope="row">Subject: ({ message.Message?.Subject.Charset })</th>
							<td>{ message.Message?.Subject.Data }</td>
						</tr>

						<tr>
							<th scope="row">Status</th>
							<td>
								{ error ? (
									<span className="ApplicationLogs-Email__detail-status ApplicationLogs-Email__detail-status-error">
										<OctagonIcon size={ 16 } />
										Error
									</span>
								) : (
									<span className="ApplicationLogs-Email__detail-status ApplicationLogs-Email__detail-status-success">
										<CheckIcon size={ 16 } />
										Sent
									</span>
								) }
							</td>
						</tr>
					</tbody>
				</table>
			</header>

			{ message.Message?.Body.Text && (
				<>
					<h3>Text ({ message.Message.Body.Text.Charset })</h3>
					<pre className="ApplicationLogs-Email__detail-body">
						{ message.Message.Body.Text.Data }
					</pre>
				</>
			) }

			{ message.Message?.Body.Html && (
				<>
					<h3>HTML ( { message.Message.Body.Html.Charset } )</h3>
					<pre className="ApplicationLogs-Email__detail-body">
						{ message.Message.Body.Html.Data }
					</pre>
				</>
			) }

			{ simplifiedError ? (
				<>
					<h3>Error Message</h3>
					<div className="ApplicationLogs-Email__detail-error">
						<p><strong>Type:</strong> { simplifiedError.type } </p>
						<br></br>
						<p><strong>Details:</strong> { simplifiedError.brief } </p>
						<br></br>
						<p><strong>Suggested Action:</strong> { simplifiedError.action } </p>
					</div>
				</>
			) : error ? (
				<>
					<h3>Error Message</h3>
					<pre className="ApplicationLogs-Email__detail-error">
						{ error.message }
					</pre>
				</>
			) : null }
		</div>
	);
};

const COLUMNS: Columns<EmailEntry> = {
	date: {
		title: 'Date',
		className: 'ApplicationLogs-Email__date',
	},
	recipient: {
		title: 'Recipient(s)',
		className: 'ApplicationLogs-Email__recipient',
		sort: [
			i => i.message.Destination?.ToAddresses?.join( ' ' ),
		],
	},
	message: {
		title: 'Message',
		className: 'ApplicationLogs-Email__message',
		sort: [
			i => i.message.Message?.Subject.Data,
		],
	},
};

interface LogTableProps {
	application: ApplicationResponse,
	data: RawEmailEntry[],
	types: TypeFilter,
}

function LogTable( props: LogTableProps ) {
	const [ expanded, setExpanded ] = useState<EmailEntry | null>( null );

	const normalized = props.data.map( normalizeEntry );
	const filtered = normalized.filter( item => {
		const type = item.error ? 'error' : 'success';
		return props.types[ type ];
	} );

	if ( ! filtered.length ) {
		return (
			<NoItemsFoundBlock
				message="🌴 Observe a beautiful, blank, empty paradise, free of distraction, worries of the world, and log entries. (Zero results found.)"
			/>
		);
	}

	return (
		<>
			<SortableTable<EmailEntry>
				className="ApplicationLogs-Nginx__table"
				columns={ COLUMNS }
				data={ filtered }
				defaultReverse
				defaultSort="date"
				isDense
				isFixed
			>
				{ item => (
					<tr
						key={ item.id }
					>
						<td className="ApplicationLogs-Email__date">
							<div className="ApplicationLogs-Email__date-wrap">
								<Timestamp
									date={ new Date( item.date ) }
								/>

								<Level
									level={ item.error ? 'error' : 'success' }
									text={ item.error ? 'Error' : 'Sent' }
									title={ item.error ? 'Error' : 'Sent' }
								/>
							</div>
						</td>
						<td className="ApplicationLogs-Email__recipient">
							{ item.message.Destination && item.message.Destination?.ToAddresses?.join( ', ' ) }
						</td>
						<td className="ApplicationLogs-Email__message">
							{ item.message.Message && (
								<>
									<span
										className="ApplicationLogs-Email__message-subject"
										onClick={ () => setExpanded( item ) }
									>
										{ item.message.Message.Subject.Data }
									</span>
									<span className="ApplicationLogs-Email__message-excerpt">
										{ item.message.Message.Body.Text ? (
											item.message.Message.Body.Text.Data.substring( 0, 200 )
										) : item.message.Message.Body.Html ? (
											<code>{ item.message.Message.Body.Html.Data.substring( 0, 200 ) }</code>
										) : '' }
									</span>
								</>
							) }

							{ item.message.RawMessage && (
								<button
									className="ApplicationLogs-Email__message-excerpt"
									onClick={ () => setExpanded( item ) }
								>
									{ item.message.RawMessage.Data }
								</button>
							) }

							<PillButton
								name="View details →"
								onClick={ () => setExpanded( item ) }
							/>
						</td>
					</tr>
				) }
			</SortableTable>
			<Modal
				isOpen={ !! expanded }
				title="Message details"
				onClose={ () => setExpanded( null ) }
			>
				{ expanded && (
					<EmailDetails
						item={ expanded }
					/>
				) }
			</Modal>
		</>
	);
}

type ConnectedLogsProps = Filter & {
	application: ApplicationResponse,
	types: TypeFilter,
};
type ConnectedLogsData = {
	logs: RawEmailEntry[],
};
const logConnect = withApiData<ConnectedLogsData, ConnectedLogsProps>(
	props => ( {
		logs: `/stack/applications/${ props.application.id }/logs/email?after=${ props.after }&before=${ props.before }&search=${ props.search }`,
	} )
);
const ConnectedLogs = logConnect( props => (
	<ApplicationLogsWarning
		logs={ props.logs }
		renderLogs={ ( logs: RawEmailEntry[] ) => (
			<LogTable
				application={ props.application }
				data={ logs }
				types={ props.types }
			/>
		) }
	/>
) );
type OwnProps = TimeProps & {
	application: ApplicationResponse,
	onTimeUpdate( t: any ): void,
};

type AllProps = OwnProps;

type State = Filter & {
	types: TypeFilter,
};

export default class EmailLogs extends React.Component<AllProps, State> {
	apiData: typeof ConnectedLogs['prototype'] | null = null;

	constructor( props: AllProps ) {
		super( props );
		this.state = {
			after: this.props.after || '4 hours ago',
			before: this.props.before || 'now',
			search: '',
			types: {
				error: true,
				success: true,
			},
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}

	onChangeFilter = ( filter: Filter ) => {
		this.apiData?.onInvalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	};

	render() {
		return (
			<div className="ApplicationLogs-Nginx">
				<Header
					after={ this.state.after }
					before={ this.state.before }
					search={ this.state.search }
					types={ this.state.types }
					onChangeFilter={ this.onChangeFilter }
					onToggleType={ types => this.setState( { types } ) }
				/>

				<ConnectedLogs
					ref={ ref => this.apiData = ref }
					after={ this.state.after }
					application={ this.props.application }
					before={ this.state.before }
					search={ this.state.search }
					types={ this.state.types }
				/>
			</div>
		);
	}
}
